.container {
  position: relative;
}

.header {
  display: flex;
  align-items: center;
}

.edit_btn {
  position: absolute;
  right: 0;
  top: 0;
}

[data-desktop='true'] .edit_btn {
  position: absolute;
  visibility: hidden;
}

[data-desktop='true'] .container:hover .edit_btn {
  visibility: visible;
}

.content {
  padding: 0;
}

[data-desktop='true'] .content {
  padding: 0 40px;
}
