.evaluation_period,
.check_in_cycles {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .evaluation_period,
[data-desktop='true'] .check_in_cycles {
  padding: 32px;
  margin-top: 32px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.check_in_cycles .form {
  display: flex;
  flex-wrap: wrap;
}

.check_in_cycles p {
  flex: 1 1 100%;
  margin-bottom: 16px;
}

.check_in_cycles label {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  margin-right: 16px;
}

.check_in_cycles .form input {
  flex: 0 0 auto;
  width: 5em;
  margin: 0 0.3em;
}

.check_in_cycles table input {
  width: 100%;
  height: 32px;
  padding: 8px;
  border: 1px solid var(--color-light-grey);
  border-radius: 4px;
  position: relative;
  z-index: 1;
  font-size: 14px;
}
