.toggle_activity_btn {
  font-weight: bold;
  color: var(--color-grey);
}

.toggle_activity_btn:hover {
  color: var(--color-primary);
}

.review {
  padding: 24px 16px;
}

[data-desktop='true'] .review {
  padding: 24px 40px;
}
