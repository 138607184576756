.container {
  --height: 40px;

  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 32px;
  width: 420px;
  max-width: 100%;
}

.header {
  text-align: center;
}

.body {
  text-align: left;
}

.footer {
  display: flex;
  justify-content: center;
}
