.button {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
}
.button:disabled {
  opacity: 0.3;
  cursor: initial;
}
.button svg {
  transition: transform 0.3s;
  transform: translateY(4px);
}
.button:hover:not(:disabled) svg {
  transform: scale(1.2) translateY(2px);
}

.round {
  text-indent: -9999em;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  border-radius: 50%;
  box-shadow: 0px 2px 14px #b8b8b863;
}
.round svg {
  transform: translateX(2px);
}
.round:hover:not(:disabled) svg {
  transform: scale(1.3) translateX(2px);
}
