.comments {
  padding: var(--spacing);
}

[data-desktop='true'] .comments {
  max-width: 700px;
  /* regular spacing + icon size + icon spacing */
  padding: var(--spacing) calc(var(--spacing) + 20px + 16px);
}

.new_comment {
  display: flex;
}

.submit {
  padding: 0 var(--space-8);
}
.submit svg path {
  fill: var(--color-dark-grey);
}
.submit:disabled svg path {
  fill: var(--color-light-grey);
}
.submit:not(:disabled):hover svg path,
.submit:not(:disabled):active svg path {
  fill: var(--color-primary);
}
