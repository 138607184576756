@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
.utils_highlight__2EBB4 {
  animation-duration: 3s;
  animation-name: utils_highlight__2EBB4;
}

@keyframes utils_highlight__2EBB4 {
  0% {
    box-shadow: 0px 0px 6px #ff000000;
  }
  10% {
    box-shadow: 0px 0px 16px var(--color-primary);
  }
  20% {
    box-shadow: 0px 0px 6px #ff000000;
  }
  30% {
    box-shadow: 0px 0px 16px var(--color-primary);
  }
  100% {
    box-shadow: 0px 0px 6px #ff000000;
  }
}

.ApplicationUsageReportScreen_reports_card__3Nci3 {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .ApplicationUsageReportScreen_reports_card__3Nci3 {
  margin-top: 32px;
  padding: 32px;
}

.ApplicationUsageReportScreen_label__3vNau {
  display: flex;
  align-items: center;
}

.ApplicationUsageReportScreen_actions__2BkEE {
  display: flex;
  justify-content: flex-end;
}

.ApplicationUsageReportScreen_table__1jyRW th {
  text-align: left;
}

.ApplicationUsageReportScreen_table__1jyRW td:not(:first-child) {
  text-align: right;
}

.ApplicationUsageReportScreen_table__1jyRW tfoot td:first-child {
  font-weight: bold;
}

.Spacer_spacer__pWk_- {
  display: block;
  line-height: 0;
}

.Divider_line__2bo5p {
  height: 1px;
  background-color: var(--color-light-grey);
}

.Icon_inherit__bypEj path {
  fill: currentColor !important;
}

.Button_btn__FepqW {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  height: 32px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 24px;
  cursor: pointer;
  border: none;
  border-radius: 16px;
}

.Button_noWrap__21GG2 {
  white-space: nowrap;
}

.Button_btn__FepqW:focus {
  outline-color: var(--color-dark-grey);
  outline-offset: 0;
  outline-width: 1px;
}

.Button_btn__FepqW:disabled {
  cursor: inherit;
}

.Button_small__1K73X {
  height: 24px;
  padding: 0 18px;
}

.Button_primary__3NtLL {
  background-color: var(--color-primary);
}

.Button_primary__3NtLL:hover {
  background-color: #cc1019;
}

.Button_primary__3NtLL:disabled {
  background-color: var(--color-light-grey);
}

.Button_secondary__tY3Oz {
  color: var(--color-primary);
  border: 1px solid #da4241;
  background-color: #fff;
}

.Button_secondary__tY3Oz:hover {
  color: #fff;
  background-color: var(--color-primary);
}

.Button_secondary__tY3Oz:disabled {
  color: var(--color-grey);
  border-color: var(--color-light-grey);
  background-color: #fff;
}

.Button_tertiary__2im_W {
  color: var(--color-grey);
  padding: 0;
  border: none;
  background-color: transparent;
}

.Button_tertiary__2im_W:hover {
  color: var(--color-primary);
}

.Button_tertiary__2im_W:disabled {
  color: var(--color-light-grey);
}

.Text_paragraph__3CCFd {
  margin: 0;
  padding: 0;
}

.Text_noWrap__zKkc3 {
  white-space: nowrap;
}

.Heading_h1__UtxLe {
  font-size: 26px;
  color: #000000;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
}

.Heading_h2__F1VUJ {
  font-size: 21px;
  color: #000000;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
}

.Heading_h3__2TJ8z {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
}

.Heading_inherit_color__8jEsJ {
  color: inherit;
}

.Progress_progress__XHfjQ {
  display: flex;
  grid-gap: var(--space-24);
  gap: var(--space-24);
  align-items: center;
}

.Progress_progress__XHfjQ.Progress_labelBefore__3-rQC {
  flex-direction: row-reverse;
}

.Progress_progress__label__3yTE5 {
  font-weight: bold;
  color: var(--color-grey);
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.Checkbox_checkbox__4zIhS[type='checkbox'] {
  position: relative;
  min-width: 24px;
  height: 24px;
  margin: 0;
  border: 1px solid var(--color-light-grey);
  border-radius: 20%;
}

.Checkbox_checkbox__4zIhS[type='checkbox']:checked {
  background-color: var(--color-secondary);
  border: 0;
}

.Checkbox_checkbox__4zIhS[type='checkbox'] {
  --focus: 1px var(--color-dark-grey);
  outline: none;
  transition: box-shadow 0.2s;
}

.Checkbox_checkbox__4zIhS[type='checkbox']:focus {
  box-shadow: 0 0 0 var(--focus);
}

.Checkbox_checkboxContainer__13FSl {
  position: relative;
  cursor: pointer;
}
.Checkbox_iconContainer__1wWv4 {
  position: absolute;
  left: 5px;
  top: 1px;
  z-index: 1;
}

.File_container__R12sk {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 25em;
  position: relative;
}

.File_input__3fi6F {
  width: 100%;
  position: relative;
  z-index: 1;
}

.File_input__3fi6F:focus {
  outline-color: var(--color-dark-grey);
  outline-offset: 0;
  outline-width: 1px;
}

.File_input__3fi6F::placeholder {
  color: #999;
}

.File_error__2lbD6 {
  flex: 0 0 100%;
  padding: 0.4em 0.5em 0.2em 0.5em;
  border-radius: 0 0 4px 4px;
  margin-top: -0.3em;
  background-color: red;
  text-align: center;
  color: white;
  font-size: 10px;
  font-weight: bold;
}

.File_centeredText__3yyt0 {
  text-align: center;
}

.Input_container__1IdvV {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.Input_input__3C4aA {
  font-size: 16px;
  height: 32px;
  width: 100%;
  padding: 8px;
  border: 1px solid var(--color-light-grey);
  border-radius: 4px;
  position: relative;
  z-index: 1;
}

.Input_input__3C4aA:focus {
  outline-color: var(--color-dark-grey);
  outline-offset: 0;
  outline-width: 1px;
}

.Input_input__3C4aA::placeholder {
  color: #999;
}

.Input_large__2EVYx {
  font-size: 18px;
  height: 64px;
  padding: 24px;
}

.Input_unit__33-Nm {
  position: absolute;
  right: 8px;
  color: #999;
}

.Input_input__3C4aA:focus + .Input_unit__33-Nm {
  color: var(--color-dark-grey);
}

.Input_error__23wd_ {
  flex: 0 0 100%;
  padding: 0.4em 0.5em 0.2em 0.5em;
  border-radius: 0 0 4px 4px;
  margin-top: -0.3em;
  background-color: red;
  text-align: center;
  color: white;
  font-size: 10px;
  font-weight: bold;
}

.Input_centeredText__ZPDcF {
  text-align: center;
}

.MultiSelect_select__1jKn3 {
    font-size: 14px;
    padding: 8px;
    border-radius: 4px;
    background: #f7f7f7;
}

.MultiSelect_checkbox__3ffdJ {
    display: inline-block;
    width: 1em;
    height: 1em;
    border: 1px solid #000;
    border-radius: 0.2em;
}

.MultiSelect_checkbox__3ffdJ:checked {
    background: var(--color-secondary) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 1331 1024' fill='rgb(255,255,255)'%3E%3Cpath d='M1199.452 0.051c-34.596 1.253-65.521 16-87.857 39.104l-0.035 0.036c-244.86 245.423-431.816 450.274-659.087 682.275l-242.074-204.519c-22.074-18.653-50.853-29.991-82.28-29.991-70.618 0-127.866 57.247-127.866 127.866 0 39.191 17.632 74.264 45.395 97.719l0.19 0.156 332.343 281.213c22.082 18.694 50.887 30.060 82.348 30.060 35.213 0 67.101-14.239 90.219-37.273l-0.004 0.004c275.333-275.921 471.365-496.316 741.381-766.945 24.073-23.276 39.019-55.862 39.019-91.939 0-70.595-57.229-127.824-127.824-127.824-1.36 0-2.715 0.021-4.065 0.063l0.197-0.005z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 0.625em 0.625em;
    background-position: center;
    border-color: transparent;
}

.MultiSelect_option__1ZwqF {
    display: flex;
    align-items: center;
    grid-gap: 0.5em;
    gap: 0.5em;
    margin: 0.25em 0;
}

.MultiSelect_heading__2RgrP {
    display: flex;
    padding: 0.5em 1em;
}

.MultiSelect_heading__2RgrP label {
    display: flex;
    grid-gap: 0.25em;
    gap: 0.25em;
}

.MultiSelect_selectList__184Xd {
    height: 15em;
    padding: 0.5em 1em;
    border: 1px solid var(--color-light-grey);
    border-radius: 4px;
    overflow: auto;
    background: var(--color-white);
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.Radio_radio__3bTjb {
  position: relative;
  min-width: 24px;
  height: 24px;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--color-light-grey);
  border-radius: 50%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.Radio_radio__3bTjb:checked {
  padding: 4px;
  background-color: white;
}

.Radio_radio__3bTjb:checked::before {
  content: '';
  background-color: var(--color-secondary);
  display: block;
  width: 100%;
  border-radius: 50%;
}

.Radio_radio__3bTjb {
  --focus: 1px var(--color-dark-grey);
  outline: none;
  transition: box-shadow 0.2s;
}

.Radio_radio__3bTjb:hover,
.Radio_radio__3bTjb:focus {
  box-shadow: 0 0 0 var(--focus);
}

.Select_select__1dXt2 {
    font-size: 14px;
    height: 32px;
    padding: 8px;
    border: 1px solid var(--color-light-grey);
    border-radius: 4px;
}

.Select_select__1dXt2:focus {
    outline-color: var(--color-dark-grey);
    outline-offset: 0;
    outline-width: 1px;
}

.StrengthMeter_container__2X1vj {
  display: grid;
}

.StrengthMeter_meter___kqyc {
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  border-radius: 3px;
}

.StrengthMeter_meter___kqyc::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.StrengthMeter_meter___kqyc::-moz-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.StrengthMeter_label__24LZ0 {
  font-size: 10px;
  text-align: center;
}

.StrengthMeter_meter___kqyc::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.StrengthMeter_meter___kqyc::-moz-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.StrengthMeter_-Weak__3MZ3e::-webkit-progress-value {
  background-color: #F25F5C;
}

.StrengthMeter_-Weak__3MZ3e::-moz-progress-bar {
  background-color: #F25F5C;
}

.StrengthMeter_-Fair__1LuRz::-webkit-progress-value {
  background-color: #FFE066;
}

.StrengthMeter_-Fair__1LuRz::-moz-progress-bar {
  background-color: #FFE066;
}

.StrengthMeter_-Good__L8OWi::-webkit-progress-value {
  background-color: #247BA0;
}

.StrengthMeter_-Good__L8OWi::-moz-progress-bar {
  background-color: #247BA0;
}

.StrengthMeter_-Strong__3SVov::-webkit-progress-value {
  background-color: #70C1B3;
}

.StrengthMeter_-Strong__3SVov::-moz-progress-bar {
  background-color: #70C1B3;
}

.Textarea_textarea__gDHhG {
  font-size: 16px;
  width: 100%;
  padding: 10px 14px;
  margin: 0;
  resize: none;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid var(--color-light-grey);
}

.Textarea_textarea__gDHhG:focus {
  outline-color: var(--color-dark-grey);
  outline-offset: 0;
  outline-width: 1px;
}

.Textarea_textarea__gDHhG::placeholder {
  color: #999;
}

.ActionButton_button__1WO7_ {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  box-shadow: none;
  border-radius: 50px;
  color: var(--color-dark-grey);
  background-color: #fff;
}

[data-desktop='true'] .ActionButton_button__1WO7_ {
  color: var(--color-grey);
  background-color: transparent;
}

.ActionButton_button__1WO7_:hover {
  color: var(--color-dark-grey);
  background-color: #fff;
}

.ActionButton_button__1WO7_ svg {
  transition: all 0.2s;
}

.ActionButton_button__1WO7_:hover svg {
  transform: scale(1.15);
}

.AddGoalButton_addGoalBtn__VxeT6 {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: var(--color-grey);
    height: 40px;
    padding: 0 18px;
}

.AddGoalButton_addGoalBtn__VxeT6:hover {
    color: var(--color-dark-grey);
    background-color: #fff;
    border-radius: 50px;
}

.AddGoalButton_addGoalBtn__VxeT6 svg {
    transition: all 0.2s;
}
.AddGoalButton_addGoalBtn__VxeT6:hover svg {
    transform: scale(1.15);
}

[data-desktop='false'] .AddGoalButton_addGoalBtn__VxeT6 {
    color: var(--color-dark-grey);
    font-weight: normal;
    background-color: #fff;
    border-radius: 50px;
}

[data-desktop='false'] .AddGoalButton_addGoalBtn__VxeT6:hover svg {
    transform: none;
}

.Card_card__3O_aa {
  background-color: #ffffff;
  border-radius: var(--space-8);
  box-shadow: 0px 2px 10px #b8b8b863;
}

.CommentCard_container__3uQkg {
  display: flex;
  flex: 1 1;
}

.CommentCard_comment__1AaKo {
  flex: 1 1;
}

.CommentCard_body__21SPW {
  padding: 12px 16px;
  background-color: #f0f0f0;
  border-radius: 4px;
  white-space: pre-line;
}

.CommentCard_header__1fvIc {
  display: flex;
  justify-content: space-between;
}

[data-desktop='true'] .CommentCard_delete__2FalO {
  visibility: hidden;
}
[data-desktop='true'] .CommentCard_container__3uQkg:hover .CommentCard_delete__2FalO {
  visibility: visible;
}
.CommentCard_delete__2FalO:hover svg path {
  fill: var(--color-primary);
}

.ProfileImage_profile_image__3ZAYj {
  --size: 40px;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
  min-width: var(--size);
  width: var(--size);
  height: var(--size);
}

.ProfileImage_profile_image__3ZAYj span {
  color: white;
  font-weight: bold;
  display: block;
  font-size: 16px;
  letter-spacing: -2px;
  transform: translateX(-1px);
}

.ProfileImage_profile_image_large__NVfnL {
  --size: 64px;
}
.ProfileImage_profile_image_large__NVfnL span {
  font-size: 24px;
  letter-spacing: -3px;
}

.ProfileImage_profile_image_small__3HE_d {
  --size: 32px;
}
.ProfileImage_profile_image_small__3HE_d span {
  font-size: 12px;
  letter-spacing: -1px;
}

.CommentsList_comments__beforeContent__7vUaA {
  color: red;
}

.CommentsList_new_comment__bEuYE {
  display: flex;
  margin-top: 16px;
}

.CommentsList_submit__3cZMh {
  padding: 0 var(--space-8);
}
.CommentsList_submit__3cZMh svg path {
  fill: var(--color-dark-grey);
}
.CommentsList_submit__3cZMh:disabled svg path {
  fill: var(--color-light-grey);
}
.CommentsList_submit__3cZMh:not(:disabled):hover svg path,
.CommentsList_submit__3cZMh:not(:disabled):active svg path {
  fill: var(--color-primary);
}

.ConfirmationModal_container__3QsP0 {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 420px;
  max-width: 100%;
  padding: 40px 32px;
}

.ConfirmationModal_actions__maQPg {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.Content_content__1PQOG {
  display: grid;
  grid-template-areas:
    'main_header'
    'sidebar'
    'main';
  grid-template-rows: auto auto 1fr;

  /* this was the only way to make it fill the entire height */
  /* flex: 1; stretches the background as expected, but not the content */
  height: calc(100vh - var(--header-height));
}

[data-desktop='false'] .Content_content__1PQOG {
  overflow: auto;
}
[data-desktop='true'] .Content_content__1PQOG {
  grid-template-areas:
    'main_header'
    'main';

  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
}
[data-desktop='true'] .Content_content__1PQOG.Content_with_sidebar__2Suo9 {
  grid-template-areas:
    'main_header sidebar'
    'main sidebar';

  grid-template-columns: 1fr 400px;
}

.Content_main__He89u {
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3em;
}
[data-desktop='true'] .Content_main__He89u {
  overflow: auto;
}
.Content_main_header__mS2TR {
  padding-top: 3em;
  grid-area: main_header;
  width: 100%;
}

.Content_main_wrapper__21com {
  max-width: calc(832px + 2 * 32px);
  width: 100%;
  padding: 0 12px;
}
[data-desktop='true'] .Content_main_wrapper__21com {
  padding: 0 32px;
}

.Content_sidebar__3ThX0 {
  grid-area: sidebar;
  position: relative;
  display: flex;
  flex-direction: column;
}

[data-desktop='true'] .Content_sidebar__3ThX0 {
  background-color: #ffffff;
  border-left: 1px solid var(--color-light-grey);
}

.Content_sidebar_tabs__NLeGf {
  padding: 16px 0;
  overflow-x: auto;
  max-width: calc(100vw - 2 * 12px);
}
[data-desktop='true'] .Content_sidebar_tabs__NLeGf {
  padding: 16px 24px;
  max-width: calc(100vw - 2 * 24px);
}

.Content_scrollable__2Vw1f {
  overflow: auto;
  flex: 1 1;
}

[data-desktop='false'] .Content_high5s_wrapper__2BpP4 {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 99;
}
[data-desktop='true'] .Content_high5s_wrapper__2BpP4 {
  border-top: 1px var(--color-light-grey) solid;
  padding: 16px;
}
.Content_high5s_box__1Omz5 {
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  text-align: left;
  overflow: hidden;
  position: relative;
}
.Content_high5s_content__PFJ67 {
  position: relative;
}
.Content_high5s_icon__3c7b0 {
  color: #f0edee;
  position: absolute;
  right: 10px;
  bottom: -30px;
  transition: transform 0.3s;
}
.Content_high5s_give_button__2fdC8 {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.TabButton_tab__3yika {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--color-grey);
  padding: 12px 8px;
  /* white-space: nowrap; */
}

.TabButton_tab_active__3LNo4 {
  color: var(--color-dark-grey);
  font-weight: bold;
}

.TabButton_tab_active__3LNo4 .TabButton_count__36zRh {
  color: var(--color-primary);
}

.TabButton_tab_desktop__1P-Ih.TabButton_tab_active__3LNo4 .TabButton_count__36zRh {
  color: #fff;
}

.CreateCompanyValueModal_container__MMIat {
  --height: 40px;

  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 32px;
  width: 420px;
  max-width: 100%;
}

.CreateCompanyValueModal_header__1UcGX {
  text-align: center;
}

.CreateCompanyValueModal_body__3ShWU {
  text-align: left;
}

.CreateCompanyValueModal_footer__tS5JD {
  display: flex;
  justify-content: center;
}

.CreateStandardModal_container__3f9rK {
  --height: 40px;

  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 32px;
  width: 420px;
  max-width: 100%;
}

.CreateStandardModal_header__yu8KZ {
  text-align: center;
}

.CreateStandardModal_body__1xnR2 {
  text-align: left;
}

.CreateStandardModal_footer__2-OJb {
  display: flex;
  justify-content: center;
}

.DocumentModal_container__1pQWl {
  --height: 40px;

  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 32px;
  width: 420px;
  max-width: 100%;
}

.DocumentModal_header__DcFgd {
  text-align: center;
}

.DocumentModal_body__qpOtj {
  text-align: left;
}

.DocumentModal_footer__KWTta {
  display: flex;
  justify-content: center;
}

.Dropdown_container__2sizE {
  --height: 32px;

  width: 100%;
  height: var(--height);
  position: relative;
}

.Dropdown_trigger__18PLw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 13px;
  padding: 0 calc(var(--height) / 2);
  height: var(--height);
  cursor: pointer;
  border: 1px solid #d3d3d3;
  border-radius: calc(var(--height) / 2);
  background-color: #ffffff;
}
.Dropdown_selected_label__36Soj {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 1em;
  text-align: start;
}

.Dropdown_container_open__3ZWEx .Dropdown_trigger__18PLw,
.Dropdown_trigger__18PLw:hover {
  background-color: #f8f8f8;
}
.Dropdown_container__2sizE .Dropdown_trigger__18PLw:focus {
  outline: 0;
  box-shadow: 0 0 0 1px var(--color-dark-grey) inset;
  border-color: var(--color-dark-grey);
}

.Dropdown_container_open__3ZWEx .Dropdown_trigger__18PLw {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: #f8f8f8;
}

.Dropdown_container_placeholder__2JRhZ .Dropdown_trigger__18PLw {
  color: var(--color-grey);
}

.Dropdown_options__Za4lJ {
  position: absolute;
  z-index: 1;
  top: var(--height);
  left: 0;
  right: 0;
  background-color: #f8f8f8;
  border: 1px solid #d3d3d3;
  border-top: 0;
  border-bottom-left-radius: calc(var(--height) / 2);
  border-bottom-right-radius: calc(var(--height) / 2);
  padding: calc(var(--height) / 2) 0;
  max-height: 200px;
  overflow-y: auto;
}

.Dropdown_option__2M0rZ {
  padding: 0;
  width: 100%;
  text-align: start;
  padding: calc(var(--height) / 4) calc(var(--height) / 2);
  line-height: 1.2;
}

.Dropdown_option__2M0rZ:hover {
  background-color: var(--color-light-grey);
}

.EditCompanyValueModal_container__1ElvN {
  --height: 40px;

  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 32px;
  width: 420px;
  max-width: 100%;
}

.EditCompanyValueModal_header__25djo {
  text-align: center;
}

.EditCompanyValueModal_body__2VlHc {
  text-align: left;
}

.EditCompanyValueModal_footer__2wp3A {
  display: flex;
  justify-content: center;
}

.EmployeeHeader_employeeHeader__QwMiF {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1em;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 2em;
}

.EmployeeHeader_fixed_give_high5_button__Jwcxb {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 99;
}

.EmployeeHeader_left__1v4j4 {
  display: flex;
  align-items: center;
}

.EmployeeHeader_employee__3qY1u {
  display: flex;
  align-items: center;
  color: #000000;
}

.EmployeeHeader_employee__3qY1u:hover {
  color: var(--color-primary);
}

.EmployeeHeader_right__3rVQ0 {
  display: flex;
  align-items: center;
}

.EmployeeHeader_high5sDisplay__2dyJd {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  color: var(--color-dark-grey);
  height: 40px;
  padding: 0 12px;
  background-color: #fff;
  border-radius: 50px;
}

.EmployeeHeader_dotsMenu__-WCA6 {
  position: relative;
  z-index: 1;
}

.EmployeeHeader_dotsBtn__1j9FG {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
}

[data-desktop='true'] .EmployeeHeader_dotsBtn__1j9FG {
  background-color: transparent;
}

.EmployeeHeader_dotsBtn__1j9FG svg path {
  fill: var(--color-primary);
}

[data-desktop='true'] .EmployeeHeader_dotsBtn__1j9FG svg path {
  fill: var(--color-dark-grey);
}

.EmployeeHeader_dotsBtn__1j9FG:hover {
  border-radius: 50%;
  background-color: #fff;
}

.EmployeeHeader_dotsBtn__1j9FG:hover svg path {
  fill: var(--color-primary);
}

.EmployeeHeader_dotsBtn__1j9FG:focus {
  border-radius: 50%;
  background-color: #fff;
}

.EmployeeHeader_dotsBtn__1j9FG:focus svg path {
  fill: var(--color-primary);
}

.EmployeeHeader_menuOptions__3EESl {
  position: absolute;
  top: 48px;
  right: 0;
  min-width: 200px;
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.EmployeeHeader_plus__b14oo {
  color: var(--color-primary);
}

.EmployeeHeader_menuOption__6RFo5 {
  text-align: start;
  padding: 0;
  cursor: pointer;
}

.NoGoal_container__3eILk {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 72px 24px;
  text-align: center;
  border: 1px dashed var(--color-grey);
  border-radius: 8px;
}

.CommentsListContainer_comments__XgFp3 {
  padding: var(--spacing);
}

[data-desktop='true'] .CommentsListContainer_comments__XgFp3 {
  max-width: 700px;
  /* regular spacing + icon size + icon spacing */
  padding: var(--spacing) calc(var(--spacing) + 20px + 16px);
}

.CommentsListContainer_new_comment__3QkK8 {
  display: flex;
}

.CommentsListContainer_submit__oOTRM {
  padding: 0 var(--space-8);
}
.CommentsListContainer_submit__oOTRM svg path {
  fill: var(--color-dark-grey);
}
.CommentsListContainer_submit__oOTRM:disabled svg path {
  fill: var(--color-light-grey);
}
.CommentsListContainer_submit__oOTRM:not(:disabled):hover svg path,
.CommentsListContainer_submit__oOTRM:not(:disabled):active svg path {
  fill: var(--color-primary);
}

.ReviewContainer_toggle_activity_btn__1d1iV {
  font-weight: bold;
  color: var(--color-grey);
}

.ReviewContainer_toggle_activity_btn__1d1iV:hover {
  color: var(--color-primary);
}

.ReviewContainer_review__3LY2R {
  padding: 24px 16px;
}

[data-desktop='true'] .ReviewContainer_review__3LY2R {
  padding: 24px 40px;
}

.GoalDetailsContainer_goal_details__19Hag {
  --spacing: 24px;
  background-color: white;
  border-bottom-left-radius: var(--space-8);
  border-bottom-right-radius: var(--space-8);
}

.GoalDetailsContainer_padded__1JY8S {
  padding: var(--spacing);
}

[data-desktop='true'] .GoalDetailsContainer_review_form_container__11AQd {
  padding: 24px;
}

.EmployeePage_toggle_all_goals__1tp1Z {
  padding: var(--space-16);
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: bold;
  color: var(--color-grey);
}
.EmployeePage_toggle_all_goals__1tp1Z svg {
  transition: all 0.3s;
}
.EmployeePage_toggle_all_goals__1tp1Z:hover {
  color: var(--color-dark-grey);
}
.EmployeePage_toggle_all_goals__1tp1Z:hover svg {
  transform: scale(1.2);
}

.EmployeePage_add_goal_wrapper__1GRO- {
  display: flex;
  justify-content: center;
}

.EvaluationPeriodModal_container__3TS6L {
  width: 300px;
  padding: 32px;
}

.EvaluationPeriodModal_container__3TS6L[data-desktop='true'] {
  width: 420px;
}

.EvaluationPeriodModal_form__2xaU5 {
  display: grid;
  grid-gap: 16px;
  gap: 16px;
  width: 100%;
}

.EvaluationPeriodModal_form__2xaU5 select {
  width: 100%;
}

.GoalCard_content__14IqN {
  flex: 1 1;
}

.GoalCard_description__1or_U {
  margin-right: 2em;
}

.GoalCard_footer__1KqxG {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.GoalCard_actions_wrapper__1tX98 {
  background-color: var(--background);
  padding-left: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  height: calc(32px + 2px + 2px);
  display: flex;

  position: absolute;
  bottom: 10px;
  right: 8px;
  padding-right: 8px;

  overflow: hidden;
  will-change: contents;
  width: 50px;
  transition: width 0.3s, border 0.3s;
}
[data-desktop='false'] [data-actions='true'] .GoalCard_actions_wrapper__1tX98 {
  border-left: 1px var(--color-light-grey) solid;
  width: 160px;
}

.GoalCard_actions_toggle__2jboX {
  padding: 4px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.GoalCard_actions__e5v3I {
  display: flex;
  margin-left: 32px;
  transition: transform 0.3s;
}
[data-actions='false'] .GoalCard_actions__e5v3I {
  transform: translateX(80px);
}
[data-actions='true'] .GoalCard_actions__e5v3I {
  transform: translateX(0);
}
[data-desktop='true'] .GoalCard_actions__e5v3I {
  margin: 0;
  transform: translateY(5px) translateX(5px);
  visibility: hidden;
}
[data-desktop='true'] .GoalCard_goal__1kitQ:hover .GoalCard_actions__e5v3I {
  visibility: visible;
}

.GoalCard_actions__e5v3I button {
  padding: 8px;
  line-height: 1;
}
[data-desktop='false'] .GoalCard_actions__e5v3I button {
  margin-left: 8px;
}
.GoalCard_actions__e5v3I button:hover {
  background-color: #eeeeee;
  border-radius: 50%;
}

.GoalCard_goal_details__1DDXk {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* force stats to wrap on mobile, so the absolute positioned toggle doesn't cover them */
  padding-right: 42px;
  flex: 1 1;
}

.GoalCard_goal_progress__dLeo9,
.GoalCard_goal_stats__YyDri {
  display: flex;
  align-items: center;
  /* leave a vertical space when stats wrap below status */
  margin-top: 1em;
}
[data-desktop='true'] .GoalCard_goal_progress__dLeo9 {
  flex: 1 1;
  min-width: 200px;
  max-width: 300px;
}
.GoalCard_goal_progress_mobile__3DwJw {
  padding: calc(var(--spacing) * 2) var(--spacing);
  margin: 0 auto;
  max-width: 400px;
}

.GoalCard_status__KDFZI {
  margin-top: 1em;
  position: relative;
  -webkit-user-select: none;
          user-select: none;
  /* mandatory to align status with stats */
  margin-top: 1em;
}

.GoalCard_status_summary__3zqq3 {
  height: 22px;
  line-height: 22px;
  border-radius: 11px;
  /* --color is set dynamically from component */
  background-color: var(--color);
  padding: 0 16px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.GoalCard_status_summary__3zqq3:disabled {
  cursor: default;
}

.GoalCard_status_list__1z2C- {
  position: absolute;
  z-index: 2;
  margin-top: 0.5em;
  padding: 8px 16px;
}

.GoalCard_status_list__1z2C- button:hover {
  color: var(--color-primary);
}

.GoalCard_status_list__1z2C- button {
  text-align: left;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.GoalCard_status_list__1z2C- button::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--color);
  border-radius: 50%;
  margin-right: 8px;
}

.input-range {
  height: 16px;
  display: flex;
  align-items: center;
}
.input-range__track {
  background-color: #f0f0f0;
  height: 16px;
  border-radius: 8px;
  flex: 1 1;
  position: initial;
}
.input-range--disabled .input-range__track--active,
.input-range__track--active {
  background-color: #c3ead8;
}
.input-range__track--background {
  margin-top: auto;
  top: initial;
  right: initial;
}

.input-range__slider {
  border: none;
  width: 32px;
  height: 32px;
  margin-left: -16px;
  margin-top: -24px;
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  cursor: ew-resize;
}

/* make this same size & position as .input-range__slider */
.input-range__label--value {
  z-index: 1;
  top: 0;
  width: 32px;
  height: 32px;
  margin-left: -16px;
  margin-top: -24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #28c37b;
  pointer-events: none;
  font-size: 12px;
  font-weight: bold;
}
.input-range__label-container {
  left: auto;
  display: block;
  text-align: center;
  pointer-events: none;
  -webkit-user-select: none;
          user-select: none;
}

.input-range__label--max,
.input-range__label--min {
  display: none;
}

.input-range--disabled .input-range__slider,
.input-range--disabled .input-range__track {
  cursor: initial;
}
.input-range--disabled .input-range__slider-container {
  display: none;
}
/* .input-range--disabled .input-range__slider {
  box-shadow: none;
  background: none;
} */

.GoalProgress_container__1e0Hb {
  display: flex;
  align-items: center;
  flex: 1 1;
}

.GoalPlaceholder_goal__N4_1O {
  --spacing: 24px;

  padding: var(--spacing);
}

.GoalPlaceholder_line__2GTfJ {
  height: 0.8125rem;
  background: #ccc;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 500% 100%;
  animation: GoalPlaceholder_move__3an3z 2s reverse linear infinite;
}

.GoalPlaceholder_heading__3eZE0 {
  height: 1.25rem;
}

.GoalPlaceholder_short__3YTT- {
  width: 108px;
}

.GoalPlaceholder_goal_status__2N_zo {
  width: 108px;
  height: 22px;
  line-height: 22px;
  border-radius: 11px;
  background-color: #c3ead8;
  padding: 0 16px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.GoalPlaceholder_goal_footer__i3VNR {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}

.GoalPlaceholder_spacer__3iu1t {
  height: 1rem;
}

@keyframes GoalPlaceholder_move__3an3z {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

.GoalForm_goal_form__3CBcQ {
  --spacing: var(--space-16);
  padding: var(--spacing);
}

[data-desktop='true'] .GoalForm_goal_form__3CBcQ {
  --spacing: var(--space-32);
}

.GoalForm_smart_box__MjF9y {
  background: #f9f9f9;
  border-radius: var(--space-8);
}

.GoalForm_smart_box_padded__WFIIc {
  padding: var(--space-16);
}

.GoalForm_smart_box_details__2iLb4 {
  padding: 24px calc(40px + 16px);
}

.GoalForm_grid__3XGxZ {
  display: grid;
  grid-template-columns: 40px -webkit-max-content -webkit-max-content;
  grid-template-columns: 40px max-content max-content;
  align-items: center;
}

[data-desktop='true'] .GoalForm_grid__3XGxZ {
  grid-column-gap: 16px;
  column-gap: 16px;
  grid-column-start: auto;
}

.GoalForm_grid_label__2ZV4i {
  grid-column: span 2;
}

.GoalForm_grid_content__12NFD {
  grid-column-start: 2;
}

[data-desktop='true'] .GoalForm_grid_content__12NFD {
  grid-column-start: auto;
}

.GoalForm_grid_divider__33M_d {
  height: 2em;
  grid-column: span 3;
}

.GoalForm_word_count__iCn5g {
  margin-top: -1.5em;
  margin-right: 0.5em;
  position: relative;
  z-index: 1;
  float: right;
  font-size: 10px;
}
.GoalForm_word_count__iCn5g ins {
  text-decoration: none;
}

.GoalForm_word_count_over__1Xqka {
  color: red;
}

.High5Button_button__3uXf- {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4px;
}
.High5Button_button__3uXf-:disabled {
  opacity: 0.3;
  cursor: initial;
}
.High5Button_button__3uXf- svg {
  transition: transform 0.3s;
  transform: translateY(4px);
}
.High5Button_button__3uXf-:hover:not(:disabled) svg {
  transform: scale(1.2) translateY(2px);
}

.High5Button_round__34Q7e {
  text-indent: -9999em;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-white);
  border-radius: 50%;
  box-shadow: 0px 2px 14px #b8b8b863;
}
.High5Button_round__34Q7e svg {
  transform: translateX(2px);
}
.High5Button_round__34Q7e:hover:not(:disabled) svg {
  transform: scale(1.3) translateX(2px);
}

.High5ListModal_container__2LvDT {
  width: 100%;
}

.High5ListModal_header__1gMh4 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 16px;
}

[data-desktop='true'] .High5ListModal_header__1gMh4 {
  padding: 32px;
}

.High5ListModal_right__1RZso {
  width: 50%;
}

[data-desktop='true'] .High5ListModal_right__1RZso {
  width: 70%;
}

.High5ListModal_high_fives_info__3zNUV {
  display: flex;
  align-items: center;
  pointer-events: none;
}

.High5ListModal_high_fives_info__3zNUV.High5ListModal_expandable__19VJ_ {
  cursor: pointer;
  pointer-events: auto;
}

.High5ListModal_high_fives_count__1GqOA {
  padding-left: 8px;
  padding-right: 16px;
  font-size: 21px;
  font-weight: bold;
}

.High5ListModal_company_values__2IUIr {
  padding: 0 8px;
}

[data-desktop='true'] .High5ListModal_company_values__2IUIr {
  padding: 0 32px;
}

.High5ListModal_company_value__3g2qj {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  padding-left: 8px;
  padding-right: 16px;
  pointer-events: none;
}

.High5ListModal_company_value__3g2qj.High5ListModal_expandable__19VJ_ {
  cursor: pointer;
  pointer-events: auto;
}

[data-desktop='true'] .High5ListModal_company_value__3g2qj {
  padding: 24px 0;
}

.High5ListModal_company_value__3g2qj .High5ListModal_high_five_container__TbvbR {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.High5ListModal_high_five__1GPnh {
  display: flex;
  align-items: center;
}

.High5ListModal_icon_wrapper__2b0DT {
  display: flex;
  justify-content: center;
  min-width: 45px;
}

.High5ListModal_icon_with_count__11nG6 {
  display: flex;
  align-items: center;
}

.High5ListModal_authors__3HwyA {
  display: flex;
}

.High5ListModal_other_authors__3O-t6 {
  position: relative;
}

.High5ListModal_other_authors__3O-t6:hover .High5ListModal_other_authors_list__2KURY {
  visibility: visible;
}

.High5ListModal_other_authors_list__2KURY {
  position: absolute;
  visibility: hidden;
  top: calc(100% + 8px);
  right: 0;
  min-width: 200px;
  padding: 16px;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.High5ListModal_comments__2dkSm {
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 16px;
}

[data-desktop='true'] .High5ListModal_comments__2dkSm {
  padding: 0;
}

.High5ListModal_comments__2dkSm .High5ListModal_right__1RZso {
  width: 100%;
}

[data-desktop='true'] .High5ListModal_comments__2dkSm .High5ListModal_right__1RZso {
  width: 70%;
}

.High5ListModal_comment__3Rw3W {
  padding: 12px 18px;
  border-radius: 10px;
  width: 100%;
  background-color: #f0f0f0;
}

.High5ListModalContainer_high_fives_container__OyrCe {
  width: 700px;
  max-width: 100%;
}

.High5Modal_container__26WzX {
  --height: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  width: 420px;
  max-width: 100%;
}

.High5Modal_employee__3gTHQ {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--height);
  border: 1px solid #d3d3d3;
  border-radius: calc(var(--height) / 2);
}

.High5Modal_employee__3gTHQ:disabled {
  cursor: initial;
}

.High5Modal_clear_employee_btn__1CMfT {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  padding: 10px;
  color: var(--color-grey);
}
.High5Modal_clear_employee_btn__1CMfT:hover {
  color: var(--color-primary);
}
.High5Modal_clear_employee_btn__1CMfT svg {
  display: block !important;
}

.High5Modal_profile_image__38yDg {
  position: absolute;
  top: 3px;
  left: 3px;
}

.High5Modal_dropdownContainer__2sgF7 {
  width: 100%;
  height: var(--height);
}

.High5Modal_dropdownContent__1cODJ {
  position: relative;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  overflow: hidden;
  /* we need z-index to overlap all content under the list.  */
  z-index: 1;
}

.High5Modal_dropdownContent__1cODJ.High5Modal_search__2KmhC {
  /* because search input and dropdown have the same z-index
    in order to show the list of employees on top of the list of
    company values we have to increase the z-index for search input */
  z-index: 2;
}

.High5Modal_options__2iysN {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.High5Modal_option__tl_pM {
  padding: 0;
  width: 100%;
  height: 32px;
}

.High5Modal_option__tl_pM:hover {
  background-color: var(--color-light-grey);
}

.High5Modal_option__tl_pM:last-child {
  margin-bottom: 16px;
}

.High5Modal_search_icon__3j3Lb {
  position: absolute;
  top: 8px;
  left: 14px;
}

.High5Modal_search_input__33Fwr {
  width: 100%;
  height: calc(var(--height) - 2px);
  border: none;
  text-align: center;
  padding: 0 14px;
  font-size: 16px;
}

.High5Modal_search_input__33Fwr:focus {
  outline: none;
}

.High5Modal_search_input__33Fwr::placeholder {
  color: var(--color-grey);
}

.High5Modal_select_value_btn__2XKzR {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(var(--height) - 2px);
  padding: 0 14px;
  font-size: 16px;
}

.High5Modal_select_value_btn__2XKzR:focus {
  outline: none;
}

.High5Modal_select_value_btn__2XKzR:hover {
  background-color: #f8f8f8;
}

.ImportUsersModal_container__3bZcd {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 640px;
  max-width: 100%;
  padding: 40px 32px;
}

.ImportUsersModal_actions__5WD6P {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.ImportUsersModal_templateButton__3p4HD {
  color: var(--color-primary);
}

.Modal_overlay__5LQYE {
  --close-button-color: var(--color-grey);
  --close-button-hover: var(--color-primary);

  position: fixed;
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* we don't really need this z-index but I used it to 'override' the styleguide z-index */
  z-index: 9999;
  overflow: auto;
  cursor: pointer;
}

[data-desktop='true'] .Modal_overlay__5LQYE {
  padding: 36px;
}

.Modal_modal__39XDB {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: initial;
  background-color: #fff;
  align-items: flex-start;
  height: auto;
  width: auto;
  border-radius: 8px;
  box-shadow: 0px 2px 14px #b8b8b863;
}

.Modal_modal_fullscreen__p-PGL {
  align-items: center;
  max-width: 100vw;
  /* height: 100%;
  width: 100%; */
  border-radius: 0;
  box-shadow: none;
}

.Modal_children__TXdZN {
  max-height: 100%;
  max-width: 100%;
}

.Modal_closeBtn__CbV1d {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  color: var(--close-button-color);
}
.Modal_closeBtn__CbV1d:hover {
  color: var(--color-primary);
}

.NotificationList_container__2_uM9 {
  position: fixed;
  padding: 8px;
  top: 65px;
  z-index: 999;
  width: 100vw;
}

[data-desktop='true'] .NotificationList_container__2_uM9 {
  padding: 16px;
  top: 72px;
  right: 0;
  width: 400px;
}

.NotificationCard_notification__2cvJW {
  position: relative;
  padding: 20px 16px;
}

.NotificationCard_close_btn__lilZE {
  position: absolute;
  top: 12px;
  right: 16px;
}

.PerformanceDefinitionsModal_performanceDefinitionsModal__13veJ > div {
  --close-button-color: var(--color-white);
}

.PerformanceDefinitionsModal_container__1zI0J {
  display: flex;
  flex-direction: column;
  max-width: 60em;
}

.PerformanceDefinitionsModal_header__2SLEx {
  padding: var(--space-32);
  text-align: center;
  background: #94d1b3;
  color: white;
}

.PerformanceDefinitionsModal_body__3kZri {
  padding: 0 var(--space-32);
  text-align: left;
}

.PerformanceDefinitionsModal_definitions__2xN4J {
  display: grid;
}

.PerformanceDefinitionsModal_definition__32MvP {
  padding: var(--space-16);
}

.PerformanceDefinitionsModal_definition__32MvP:not(:last-child) {
  border-bottom: 1px solid var(--color-light-grey);
}

.PerformanceDefinitionsModal_definition__term__17QT3 {
  font-weight: 700;
}

.PerformanceDefinitionsModal_definition__data__2CDDx ul {
  padding: 0 0 0 1em;
  margin: 0;
}

@media screen and (min-width: 1200px) {
  .PerformanceDefinitionsModal_container__1zI0J {
    display: flex;
    flex-direction: column;
    width: 60em;
  }
}

@media screen and (min-width: 481px) {
  .PerformanceDefinitionsModal_definition__32MvP {
    display: grid;
    grid-template-columns: 5em 1fr;
    grid-gap: 1em;
    gap: 1em;
  }
}

.PerformanceReviewModal_performanceReviewModal__3qXM5 > div {
  --close-button-color: var(--color-white);
}

.PerformanceReviewModal_container__q6f2w {
  display: flex;
  flex-direction: column;
  max-width: 60em;
}

.PerformanceReviewModal_header__2DWTm {
  padding: var(--space-32) var(--space-32) 3em;
  text-align: center;
  background: #94d1b3;
  color: white;
}

.PerformanceReviewModal_header__2DWTm small {
  display: block;
  margin-bottom: 0.3em;
}

.PerformanceReviewModal_body__2Mwe1 {
  padding: 0 var(--space-32);
  text-align: left;
}

.PerformanceReviewModal_footer__N5lVi {
  display: flex;
  justify-content: center;
  padding: var(--space-8) var(--space-32);
  background: var(--color-light-grey);
}

.PerformanceReviewModal_period__7MoVP {
  font-style: italic;
}

.PerformanceReviewModal_comments__1LCRt {
  max-width: 40em;
  margin: -2.5em auto 3em;
}

.PerformanceReviewModal_comment__3BmFc {
  padding: 0 var(--space-16);
  margin: 0;
  background: white;
}

.PerformanceReviewModal_comment__3BmFc:first-child {
  padding-top: var(--space-16);
  padding-bottom: var(--space-16);
  margin-bottom: 1em;
  box-shadow: 0 0 1em rgba(0 0 0 / 0.2);
}

.PerformanceReviewModal_scores__18uiw {
  max-width: 40em;
  margin: 0 auto;
}

.PerformanceReviewModal_test__3xqpB {
  background: red;
}

@media screen and (min-width: 1200px) {
  .PerformanceReviewModal_container__q6f2w {
    display: flex;
    flex-direction: column;
    width: 60em;
  }
}

.StandardsRatings_ratings__1vA1d {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.StandardsRatings_rating__n7BJy {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.75em;
  background: #e3e3e3;
  border: none;
  border-radius: 1em;
  font-size: 0.875rem;
}

.StandardsRatings_rating__n7BJy:disabled {
  cursor: default;
}

.StandardsRatings_rating__n7BJy.StandardsRatings_active__oPMwA {
  background-color: #94d1b3;
  color: white;
}

@media screen and (min-width: 540px) {
  .StandardsRatings_rating__n7BJy {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .StandardsRatings_rating__n7BJy {
    min-width: 10em;
    padding: 0.75em 1em;
  }
}

.EmployeeScore_score__WHdYV {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--color-light-grey);
}

.PipBadge_pip__3nP0g {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 6px 8px 5px 8px;
  display: inline-block;
  background-color: #ff8188;
  border-radius: 40px;
}

.PipBadge_small__M_HN- {
  padding: 4px 8px 3px 8px;
}

.PIPModal_container__eshDE {
  --height: 40px;

  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 32px;
  width: 420px;
  max-width: 100%;
}

.PIPModal_header__3Kr52 {
  text-align: center;
}

.PIPModal_body__1rsl5 {
  text-align: left;
}

.PIPModal_footer__2MRky {
  display: flex;
  justify-content: center;
}

.ReassignUsersModal_container__1oh1M {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 640px;
  max-width: 100%;
  padding: 40px 32px;
}

.ReassignUsersModal_actions__2uV1K {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.RequestMeetingModal_container__2vKhr {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 420px;
  max-width: 100%;
  padding: 40px 32px;
}

.RequestMeetingModal_manager_profile__EU9aY {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border: 1px solid var(--color-light-grey);
  border-radius: 24px;
}

.RequestMeetingModal_profile_image_wrapper__1W3fp {
  position: absolute;
  top: 2px;
  left: 2px;
}

.Review_container__35cnJ {
  position: relative;
}

.Review_header__2N0cP {
  display: flex;
  align-items: center;
}

.Review_edit_btn__d9FRh {
  position: absolute;
  right: 0;
  top: 0;
}

[data-desktop='true'] .Review_edit_btn__d9FRh {
  position: absolute;
  visibility: hidden;
}

[data-desktop='true'] .Review_container__35cnJ:hover .Review_edit_btn__d9FRh {
  visibility: visible;
}

.Review_content__11Zko {
  padding: 0;
}

[data-desktop='true'] .Review_content__11Zko {
  padding: 0 40px;
}

.ReviewForm_container__2_5iq {
  padding: 24px 16px;
  background-color: #f7f7f7;
  border-radius: 0;
}

[data-desktop='true'] .ReviewForm_container__2_5iq {
  border-radius: 8px;
}

.ReviewForm_header__3cAyF {
  display: flex;
  align-items: center;
}

.ReviewForm_content__Mqbup {
  padding: 0;
}

[data-desktop='true'] .ReviewForm_content__Mqbup {
  padding: 0 40px;
}

.ReviewForm_add_review_btn_wrapper__3aBNC {
  display: flex;
  justify-content: center;
}

[data-desktop='true'] .ReviewForm_add_review_btn_wrapper__3aBNC {
  justify-content: flex-start;
}

.ReviewCard_review__status__2ePx4 {
  display: flex;
  align-items: center;
  height: 22px;
  padding: 0 16px;
  border-radius: 11px;
  position: relative;
  background-color: var(--color);
  line-height: 22px;
  -webkit-user-select: none;
          user-select: none;
  white-space: nowrap;
}

.ReviewCard_review__progress__CmgJZ {
  flex: 1 0 auto;
}

.ReviewCard_review__date__1OFZY {
  display: flex;
  grid-gap: 0.3em;
  gap: 0.3em;
  font-size: 11px;
  color: var(--color-grey);
}

.ReviewCard_extras__1y--J {
  margin-top: var(--spacing);
}

@media screen and (min-width: 961px) {
  .ReviewCard_extras__1y--J {
    display: flex;
    grid-gap: var(--spacing);
    gap: var(--spacing);
    align-items: center;
  }
}

@media screen and (max-width: 960px) {
  .ReviewCard_extras__1y--J {
    display: grid;
    grid-gap: var(--spacing);
    gap: var(--spacing);
    grid-template-columns: auto 1fr;
  }

  .ReviewCard_review__date__1OFZY {
    justify-self: end;
  }

  .ReviewCard_review__progress__CmgJZ {
    grid-column: 1 / -1;
    grid-row: 2;
  }
}

.ReviewCard_review__comments__2WViU {
  padding: 0 var(--spacing) var(--spacing);
}

.ReviewCard_more_info__15KFY {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin: 2em 2em 0 0;
  background: var(--color-light-grey);
  color: var(--color-dark-grey);
  font-size: 1em;
  float: right;
}

.StandardsScores_review__scores__3yhuA,
.StandardsScores_review__notes__5Lgpn {
  padding: 24px;
}

.StandardsScores_review__notes__5Lgpn {
  padding-top: 0;
}

.StandardsScores_review__scores__3yhuA .StandardsScores_score__2Vwi2 {
  border-bottom: 1px solid #000;
}

.Score_score__4Lqjr {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--color-light-grey);
}

.EmployeeReviewCard_card__2cV55 {
  --spacing: var(--space-16);

  padding: var(--spacing);
}

.EmployeeReviewCard_card__header__2UJ5q {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.EmployeeReviewCard_extras__1oGcp {
  display: flex;
  grid-gap: var(--spacing);
  gap: var(--spacing);
  align-items: center;
  margin-top: var(--spacing);
}

.EmployeeReviewCard_review__status__2nmHZ {
  display: flex;
  align-items: center;
  height: 22px;
  padding: 0 16px;
  border-radius: 11px;
  position: relative;
  background-color: var(--color);
  line-height: 22px;
  -webkit-user-select: none;
          user-select: none;
  white-space: nowrap;
}

.EmployeeReviewCard_review__progress__3Iqle {
  flex: 1 0 auto;
}

.EmployeeReviewCard_review__date__2FkdU {
  display: flex;
  grid-gap: 0.3em;
  gap: 0.3em;
  font-size: 11px;
  color: var(--color-primary);
}

.EmployeeReviewCard_extras__1oGcp {
  display: flex;
  grid-gap: var(--spacing);
  gap: var(--spacing);
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing);
}

.EmployeeReviewCard_review__comments__2LAXY {
  padding: 0 var(--spacing) var(--spacing);
}

.EmployeeReviewCard_more_info__39RNm {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: var(--color-light-grey);
  color: var(--color-dark-grey);
  font-size: 1em;
}

.EmployeeReviewCard_more_info__39RNm:hover,
.EmployeeReviewCard_more_info__39RNm:focus {
  background: var(--color-dark-grey);
  color: var(--color-white);
}

.Table_dataTable__oFUpL {
  width: 100%;
  border-collapse: collapse;
  margin: var(--space-24) 0;
  background-color: var(--color-white);
}

.Table_dataTable__oFUpL th,
.Table_dataTable__oFUpL td {
  border: 1px solid var(--color-light-grey);
  padding: var(--space-8) var(--space-4);
}

.Table_dataTable__oFUpL tbody tr:focus-within td,
.Table_dataTable__oFUpL tbody tr:hover td {
  background: var(--color-light-grey);
}

.Table_dataTable__oFUpL tbody tr:focus-within td:not(:first-child),
.Table_dataTable__oFUpL tbody tr:hover td:not(:first-child) {
  border-left-color: var(--color-white);
}

.Table_dataTable__oFUpL tbody tr:focus-within td:not(:last-child),
.Table_dataTable__oFUpL tbody tr:hover td:not(:last-child) {
  border-right-color: var(--color-white);
}

.TaskList_tasks__3e9qa {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  max-height: 100%;
}

[data-desktop='true'] .TaskList_tasks_container__kGFkl {
  padding-left: 24px;
  padding-right: 24px;
}

.TaskList_tasks_list__3ZazL {
  overflow-y: auto;
  flex: 1 1;
  max-height: 100%;
}

.TaskList_task__3adXw {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ToggleableCard_card__DyxsL {
  --spacing: 16px;
}

.ToggleableCard_summary__3E7RB {
  padding: var(--spacing);
  position: relative;
  /* --background is set dynamically from component */
  background-color: var(--background);
  border-radius: var(--space-8);
}
.ToggleableCard_summary_expanded__3QRlp {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.ToggleableCard_toggle__Anmxh {
  cursor: pointer;
  transition: transform 0.3s;
  /* this is ok here, since the title is accessible and has the same feature and the element has tabIndex -1 */
  outline: none;
}
.ToggleableCard_toggle_open__1tHsz {
  transform: rotate(180deg);
}
.ToggleableCard_toggle__Anmxh:hover {
  transform: translateY(2px);
}
.ToggleableCard_toggle_open__1tHsz:hover {
  transform: rotate(180deg) translateY(2px);
}

.ToggleableCard_content__1dtpj {
  flex: 1 1;
}

.ToggleableCard_header__3w95C {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1 1;
}

.ToggleableCard_footer__bWg0Z {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.ToggleableCard_title__26y-0 {
  padding: 0;
  line-height: 1.2;
  text-align: start;
  padding-right: 2em;
}
.ToggleableCard_title_button__1IU5J {
  cursor: pointer;
}
.ToggleableCard_title_button__1IU5J:hover {
  color: var(--color-primary);
}

.Header_header__r_God {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  padding: 0 16px;
  border-bottom: 1px solid var(--color-light-grey);
  background-color: #ffffff;
}

[data-desktop='true'] .Header_header__r_God {
  padding: 0 32px;
}

.Header_logo__3aT-p {
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  color: var(--color-dark-grey);
}

[data-desktop='true'] .Header_logo__3aT-p {
  font-size: 20px;
}

.Header_logo__3aT-p svg {
  margin-left: 0.5em;
  transition: all 0.2s;
}

.Header_logo__3aT-p:hover svg {
  transform: scale(1.15);
}

.Header_period__1oHuW {
  display: flex;
  align-items: center;
  margin-top: 0.3em;
  position: relative;
  color: var(--color-dark-grey);
}

@media (min-width: 560px) {
  .Header_navigation__1IfqD {
    display: flex;
    align-items: center;
  }

  .Header_period__1oHuW {
    padding-left: 3em;
    margin-top: 0;
  }
}

.Header_period__1oHuW button:hover svg path {
  fill: var(--color-primary);
}

.Header_evaluation_period__3RkCA {
  padding-left: 1em;
  margin-left: 1em;
  border-left: 1px solid var(--color-dark-grey);
  color: var(--color-primary);
}

.Header_menu__14V7- {
  display: flex;
  align-items: center;
}

.Header_team__lcuMq {
  display: flex;
  align-items: center;
}

.Header_team_icon__2b8UM {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--color-light-grey);
  border-radius: 50%;
}

.Header_profile__3baHn {
  position: relative;
}

.Header_profile_button__hZhYW {
  display: flex;
  align-items: center;
  line-height: 1.3;
  text-align: start;
}

.Header_profile_details__2bwXo {
  display: block;
  padding: 0 1.25em;
  text-transform: capitalize;
}

.Header_profile_options__1Qc2V {
  display: flex;
  flex-direction: column;
  min-width: 180px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.Header_profile_options__1Qc2V button {
  padding: 15px 25px;
  border-top: 1px solid var(--color-light-grey);
  line-height: 1.3;
  text-align: left;
}

.Header_profile_options__1Qc2V button:first-child {
  border: none;
}

.Header_profile_options__1Qc2V button:hover {
  color: var(--color-primary);
}

.Header_period_options__2TO_J,
.Header_cycle_options__3RSGX {
  display: flex;
  flex-direction: column;
  min-width: 180px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.Header_period_options__2TO_J button,
.Header_cycle_options__3RSGX button {
  padding: 15px 25px;
  line-height: 1.3;
  text-align: left;
}

.Header_period_options__2TO_J button:not(:first-child),
.Header_cycle_options__3RSGX button:not(:first-child) {
  border-top: 1px solid var(--color-light-grey);
}

.Header_period_options__2TO_J button:hover,
.Header_period_options__2TO_J button:focus,
.Header_cycle_options__3RSGX button:hover,
.Header_cycle_options__3RSGX button:focus {
  color: var(--color-primary);
}

.CheckInCyclesScreen_evaluation_period__zf4cu,
.CheckInCyclesScreen_check_in_cycles__1JIsk {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .CheckInCyclesScreen_evaluation_period__zf4cu,
[data-desktop='true'] .CheckInCyclesScreen_check_in_cycles__1JIsk {
  padding: 32px;
  margin-top: 32px;
}

.CheckInCyclesScreen_actions__13VGt {
  display: flex;
  justify-content: flex-end;
}

.CheckInCyclesScreen_check_in_cycles__1JIsk .CheckInCyclesScreen_form__tHb3D {
  display: flex;
  flex-wrap: wrap;
}

.CheckInCyclesScreen_check_in_cycles__1JIsk p {
  flex: 1 1 100%;
  margin-bottom: 16px;
}

.CheckInCyclesScreen_check_in_cycles__1JIsk label {
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  margin-right: 16px;
}

.CheckInCyclesScreen_check_in_cycles__1JIsk .CheckInCyclesScreen_form__tHb3D input {
  flex: 0 0 auto;
  width: 5em;
  margin: 0 0.3em;
}

.CheckInCyclesScreen_check_in_cycles__1JIsk table input {
  width: 100%;
  height: 32px;
  padding: 8px;
  border: 1px solid var(--color-light-grey);
  border-radius: 4px;
  position: relative;
  z-index: 1;
  font-size: 14px;
}

.CreateUserScreen_users__1IXWO {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .CreateUserScreen_users__1IXWO {
  margin-top: 32px;
  padding: 32px;
}

.CreateUserScreen_form__1xQBq {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 0.5rem));
  grid-gap: 1rem;
  gap: 1rem;
}

.CreateUserScreen_managerInput__2qrKF select {
  width: 100%;
}

.CreateUserScreen_employees__1z4xb {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 0.5rem));
  grid-gap: 1rem;
  gap: 1rem;
  grid-column: span 2;
}

.CreateUserScreen_managing__2xrcO {
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  background: #f7f7f7;
}

.CreateUserScreen_managingHeader__1uSTW {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.5em 1em;
}

.CreateUserScreen_managingHeader__1uSTW button {
  display: flex;
  align-items: center;
  grid-gap: 0.25em;
  gap: 0.25em;
  font-size: 0.875em;
  color: var(--color-primary);
}

.CreateUserScreen_managingBody__1wC_s {
  height: 15em;
  padding: 0.5em 1em;
  border: 1px solid var(--color-light-grey);
  border-radius: 4px;
  overflow: auto;
  background: var(--color-white);
}

.CreateUserScreen_managingEmployee__1iAOa {
  display: flex;
  grid-gap: 0.5em;
  gap: 0.5em;
  align-items: center;
  margin: 0.25em 0;
}

.CreateUserScreen_actions__Vo8h9 {
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  margin: 1em 0;
}

.EmployeeCard_employee__1NBkQ {
  padding: var(--space-16);
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

[data-desktop='true'] .EmployeeCard_employee__1NBkQ {
  flex-direction: row;
  text-align: initial;
}

.EmployeeCard_employee__1NBkQ:hover {
  box-shadow: 0px 2px 10px #999999;
}

.EmployeeCard_stats__1zj2x {
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-desktop='true'] .EmployeeCard_stats__1zj2x {
  justify-content: flex-start;
}

.AdminDashboard_team_header__1YpNY {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.AdminDashboard_search_wrapper__WQawp {
  position: relative;
  width: 100%;
}
[data-desktop='true'] .AdminDashboard_search_wrapper__WQawp {
  margin-left: var(--space-32);
  max-width: 300px;
}
.AdminDashboard_search__nic__ {
  border-radius: var(--space-16);
  height: var(--space-32);
  border: 1px solid var(--color-light-grey);
  padding: 0 var(--space-16) 0 38px;
  width: 100%;
  font-size: 16px;
}
.AdminDashboard_search__nic__::placeholder {
  color: #aaaaaa;
}

.AdminDashboard_search_icon__2edXK {
  position: absolute;
  left: 12px;
  top: 5px;
  pointer-events: none;
}

.AdminDashboard_employee_list__2r0VK {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: var(--space-16);
  gap: var(--space-16);
}

[data-desktop='true'] .AdminDashboard_employee_list__2r0VK {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.AdminDashboard_employee_list__2r0VK li {
  display: flex;
  flex-direction: column;
}

.AdminDashboard_employee_link__gSogV {
  text-decoration: none;
  color: inherit;

  display: flex;
  flex-direction: column;
  flex: 1 1;
}


.SidebarLayout_content__3TTRk {
  display: grid;
  grid-template-areas:
    'main_header'
    'sidebar'
    'main';
  grid-template-rows: auto auto 1fr;

  /* this was the only way to make it fill the entire height */
  /* flex: 1; stretches the background as expected, but not the content */
  height: calc(100vh - var(--header-height));
}

[data-desktop='false'] .SidebarLayout_content__3TTRk {
  overflow: auto;
}
[data-desktop='true'] .SidebarLayout_content__3TTRk {
  grid-template-areas:
    'main_header'
    'main';

  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
}
[data-desktop='true'] .SidebarLayout_content__3TTRk.SidebarLayout_with_sidebar__1rHQT {
  grid-template-areas:
    'main_header sidebar'
    'main sidebar';

  grid-template-columns: 1fr 400px;
}

.SidebarLayout_main__1T-W9 {
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3em;
}
[data-desktop='true'] .SidebarLayout_main__1T-W9 {
  overflow: auto;
}
.SidebarLayout_main_header__3bost {
  padding-top: 3em;
  grid-area: main_header;
  width: 100%;
}

.SidebarLayout_main_wrapper__10A2b {
  max-width: calc(832px + 2 * 32px);
  width: 100%;
  padding: 0 12px;
}
[data-desktop='true'] .SidebarLayout_main_wrapper__10A2b {
  padding: 0 32px;
}

.SidebarLayout_sidebar__2hqJo {
  grid-area: sidebar;
  position: relative;
  display: flex;
  flex-direction: column;
}

[data-desktop='true'] .SidebarLayout_sidebar__2hqJo {
  background-color: #ffffff;
  border-left: 1px solid var(--color-light-grey);
}

.SidebarLayout_sidebar_tabs__2MXv9 {
  padding: 16px 0;
  overflow-x: auto;
  max-width: calc(100vw - 2 * 12px);
}
[data-desktop='true'] .SidebarLayout_sidebar_tabs__2MXv9 {
  padding: 16px 24px;
  max-width: calc(100vw - 2 * 24px);
}

.SidebarLayout_scrollable__fyWSV {
  overflow: auto;
  flex: 1 1;
}

[data-desktop='false'] .SidebarLayout_high5s_wrapper__27saf {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 99;
}
[data-desktop='true'] .SidebarLayout_high5s_wrapper__27saf {
  border-top: 1px var(--color-light-grey) solid;
  padding: 16px;
}
.SidebarLayout_high5s_box__1Bk-z {
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  text-align: left;
  overflow: hidden;
  position: relative;
}
.SidebarLayout_high5s_content__3LB_D {
  position: relative;
}
.SidebarLayout_high5s_icon__116Z- {
  color: #f0edee;
  position: absolute;
  right: 10px;
  bottom: -30px;
  transition: transform 0.3s;
}
.SidebarLayout_high5s_give_button__3buUl {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}

.Tabs_tabs__1d5Jx {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}

.Tabs_tabs__header__2Nadv {
  display: flex;
}

.Tabs_tabs__1d5Jx.Tabs_hasTabs__2yRxo .Tabs_tabs__header__2Nadv {
  border-bottom: 1px solid var(--color-light-grey);
}

[data-desktop='true'] .EvaluationDetailsContainer_check_in_container__3OWwG {
  padding: 24px;
}

.EvaluationDetailsContainer_review_container__RZwor {
  padding: 24px 16px;
}

[data-desktop='true'] .EvaluationDetailsContainer_review_container__RZwor {
  padding: 24px 40px;
}

.EvaluationDetailsContainer_pip_container__3uMxl {
  padding: 24px 16px;
}

[data-desktop='true'] .EvaluationDetailsContainer_pip_container__3uMxl {
  padding: 24px;
}

.EvaluationDetailsContainer_pip_card__2MMCM {
  padding: 24px 16px;
}

.EvaluationDetailsContainer_pip_content__2K_4e {
  display: flex;
  align-items: baseline;
}

[data-desktop='true'] .EvaluationDetailsContainer_pip_text_wrapper__1vAIb {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.EvaluationDetailsContainer_pip_documents__2GQ8g {
  flex: 1 1 100%;
  padding: 0;
  margin: 1rem 0;
  list-style: none;
}

.EvaluationDetailsContainer_pip_document__12M7Y {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
}

.EvaluationDetailsContainer_pip_document__12M7Y a {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  align-items: center;
}

.EvaluationDetailsContainer_file_button__wjOzO {
  font-size: 0.875em;
  text-transform: uppercase;
}

.EvaluationDetailsContainer_file_button__wjOzO:hover,
.EvaluationDetailsContainer_file_button__wjOzO:focus {
  color: var(--color-primary);
}

.ManagerDashboard_team_header__26TwF {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}

.ManagerDashboard_search_wrapper__3K5Gh {
  position: relative;
  width: 100%;
}
[data-desktop='true'] .ManagerDashboard_search_wrapper__3K5Gh {
  margin-left: var(--space-32);
  max-width: 300px;
}
.ManagerDashboard_search__29YSC {
  border-radius: var(--space-16);
  height: var(--space-32);
  border: 1px solid var(--color-light-grey);
  padding: 0 var(--space-16) 0 38px;
  width: 100%;
  font-size: 16px;
}
.ManagerDashboard_search__29YSC::placeholder {
  color: #aaaaaa;
}

.ManagerDashboard_search_icon__1AEcB {
  position: absolute;
  left: 12px;
  top: 5px;
  pointer-events: none;
}

.ManagerDashboard_employee_list__1tjBo {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: var(--space-16);
  gap: var(--space-16);
}

[data-desktop='true'] .ManagerDashboard_employee_list__1tjBo {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.ManagerDashboard_employee_list__1tjBo li {
  display: flex;
  flex-direction: column;
}

.ManagerDashboard_employee_link__aRRaG {
  text-decoration: none;
  color: inherit;

  display: flex;
  flex-direction: column;
  flex: 1 1;
}

.ManagerDashboard_add_user_button__1eMeU {
  color: var(--color-primary);
}

.GoalScreen_goalScreen__2U00Y {
    background-color: #ffffff;
    border-radius: var(--space-8);
    box-shadow: 0px 2px 10px #b8b8b863;
    display: flex;
    flex-direction: column;
}

.GoalScreen_pad32__26ceW > div:first-child, .GoalScreen_pad16__1_2ZW > div:first-child {
    justify-content: space-between;
    align-items: flex-start;
}

.GoalScreen_pad32__26ceW {
    padding: var(--space-32) var(--space-32) 0 var(--space-32);
}

.GoalScreen_pad16__1_2ZW {
    padding: var(--space-16) var(--space-16) var(--space-4) var(--space-16);
}

.EmployeePerformanceReportScreen_reports_card__2xCsa {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .EmployeePerformanceReportScreen_reports_card__2xCsa {
  margin-top: 32px;
  padding: 32px;
}

.EmployeePerformanceReportScreen_label__3WcKO {
  display: flex;
  align-items: center;
}

.EmployeePerformanceReportScreen_actions__2xNhb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.EmployeePerformanceReportScreen_table__eHFAz th {
  text-align: left;
}

.EmployeePerformanceReportScreen_table__eHFAz td:not(:first-child) {
  text-align: right;
}

.EmployeePerformanceReportScreen_table__eHFAz tfoot td:first-child {
  font-weight: bold;
}

.EmployeePerformanceReportScreen_onPIP__WSzTT {
  color: var(--color-primary);
}



.EmployeeStandardsPerformanceReportScreen_reports_card__3wpa8 {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .EmployeeStandardsPerformanceReportScreen_reports_card__3wpa8 {
  margin-top: 32px;
  padding: 32px;
}

.EmployeeStandardsPerformanceReportScreen_label__3O6V7 {
  display: flex;
  align-items: center;
}

.EmployeeStandardsPerformanceReportScreen_actions__cLC03 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.EmployeeStandardsPerformanceReportScreen_table__2wZnC th {
  text-align: left;
}

.EmployeeStandardsPerformanceReportScreen_table__2wZnC tfoot td:first-child {
  font-weight: bold;
}

.EmployeeStandardsPerformanceReportScreen_onPIP__OYMw8 {
  color: var(--color-primary);
}



.EvaluationPeriodsScreen_evaluation_periods__3O_cs {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "intro button"
    "table table";
  align-items: center;
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .EvaluationPeriodsScreen_evaluation_periods__3O_cs {
  margin-top: 32px;
  padding: 32px;
}

.EvaluationPeriodsScreen_evaluation_periods__3O_cs p {
  grid-area: intro;
}

.EvaluationPeriodsScreen_evaluation_periods__3O_cs button {
  grid-area: button;
}

.EvaluationPeriodsScreen_evaluation_periods__3O_cs table {
  grid-area: table;
}

.LoginScreen_container__3z7pG {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

[data-mobile='true'] .LoginScreen_loginContainer__R8fC0 {
    background-color: #ffffff;
    border-radius: var(--space-8);
    display: flex;
    flex-direction: column;
    padding: calc(var(--space-24) + var(--space-24));
    width: 100%;
    height: 100%;
    padding-top: 20vh;
}

[data-mobile='false'] .LoginScreen_loginContainer__R8fC0 {
    background-color: #ffffff;
    border-radius: var(--space-8);
    box-shadow: 0px 2px 10px #b8b8b863;
    display: flex;
    flex-direction: column;
    padding: calc(var(--space-24) + var(--space-24));
    width: 384px;
}

.LoginScreen_formContainer__1iZzD{
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
}

.LoginScreen_formContainer__1iZzD > * > input {
    text-align: center;
}

.LoginScreen_centered__3rqZX {
    display: flex;
    justify-content: center;
}

.LoginScreen_forgotPassword__3xJRf {
    display: flex;
    justify-content: flex-end;
    margin-top: -16px;
    font-size: 10px;
}

.LoginScreen_forgotPassword__3xJRf a {
    color: grey;
}

.ManagerPerformanceReportScreen_reports_card__30O5Y {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .ManagerPerformanceReportScreen_reports_card__30O5Y {
  margin-top: 32px;
  padding: 32px;
}

.ManagerPerformanceReportScreen_label__u9Rx0 {
  display: flex;
  align-items: center;
}

.ManagerPerformanceReportScreen_actions__Z9u8N {
  display: flex;
  justify-content: flex-end;
}

.ManagerPerformanceReportScreen_table__2Nd6z th {
  text-align: left;
}

.ManagerPerformanceReportScreen_table__2Nd6z td:not(:first-child) {
  text-align: right;
}

.ManagerPerformanceReportScreen_table__2Nd6z tfoot td:first-child {
  font-weight: bold;
}

.ManagerScreen_back_button__3xnM2 {
  margin-right: var(--space-16);
  transform: rotate(180deg);
}

.ManagerScreen_team_header__LcTM7 {
  display: flex;
}

.ManagerScreen_team_header__LcTM7 h1 {
  margin-right: var(--space-32);
}

.ManagerScreen_search_wrapper__2cS2a {
  position: relative;
  width: 100%;
}
[data-desktop='true'] .ManagerScreen_search_wrapper__2cS2a {
  margin-left: auto;
  margin-right: 0;
  max-width: 300px;
  align-self: end;
}
.ManagerScreen_search__2EhO- {
  border-radius: var(--space-16);
  height: var(--space-32);
  border: 1px solid var(--color-light-grey);
  padding: 0 var(--space-16) 0 38px;
  width: 100%;
  font-size: 16px;
}
.ManagerScreen_search__2EhO-::placeholder {
  color: #aaaaaa;
}

.ManagerScreen_search_icon__2Ha2x {
  position: absolute;
  left: 12px;
  top: 5px;
  pointer-events: none;
}

.ManagerScreen_employee_list__3ctMI {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: var(--space-16);
  gap: var(--space-16);
}

[data-desktop='true'] .ManagerScreen_employee_list__3ctMI {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.ManagerScreen_employee_list__3ctMI li {
  display: flex;
  flex-direction: column;
}

.ManagerScreen_employee_link__28Jax {
  text-decoration: none;
  color: inherit;

  display: flex;
  flex-direction: column;
  flex: 1 1;
}


.ManageStandards_manage_standards_card__2Taqv {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .ManageStandards_manage_standards_card__2Taqv {
  margin-top: 32px;
  padding: 32px;
}

.ManageStandards_card_header__lZX1N,
.ManageStandards_card_header__actions__38OMr {
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}

.ManageStandards_label__34BPf {
  display: flex;
  align-items: center;
}

.ManageStandards_standards__list__2ypOB {
  display: grid;
  grid-gap: 2rem;
  gap: 2rem;
}

.ManageStandards_standard__3TcqN {
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-areas: "title actions" "body actions";
  grid-template-columns: 1fr auto;
}

.ManageStandards_standard__3TcqN.ManageStandards_is_editable__DWYoR {
  grid-template-areas: "editable title actions" "editable body actions";
  grid-template-columns: 1rem 1fr auto;
}

.ManageStandards_standard__3TcqN:not(:first-child) {
  padding-top: 2rem;
  border-top: 1px solid var(--color-light-grey);;
}

.ManageStandards_standard__header__2M5WB {
  grid-area: title;
}

.ManageStandards_standard__header__2M5WB h3,
.ManageStandards_standard__body__36kht p {
  margin: 0;
}

.ManageStandards_standard__body__36kht {
  grid-area: body;
}

.ManageStandards_standard__actions__3we6t {
  grid-area: actions;
  align-self: start;
}

.ManageStandards_standard__edit__3LJYV {
  grid-area: editable;
}

.EditStandardModal_container__EQMx9 {
  --height: 40px;

  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  gap: 1rem;
  padding: 32px;
  width: 420px;
  max-width: 100%;
}

.EditStandardModal_header__1J7DR {
  text-align: center;
}

.EditStandardModal_body__3cY8L {
  text-align: left;
}

.EditStandardModal_footer__1GzaI {
  display: flex;
  justify-content: center;
}

.ManageValues_manage_values_card__1icPk {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .ManageValues_manage_values_card__1icPk {
  margin-top: 32px;
  padding: 32px;
}

.ManageValues_card_header__mxLjW,
.ManageValues_card_header__actions__3YhwT {
  display: flex;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}

.ManageValues_label__3vu6B {
  display: flex;
  align-items: center;
}

.ManageValues_values__list__1FKKo {
  display: grid;
  /* gap: 2rem; */
}

.ManageValues_value__1Rta3 {
  display: grid;
  grid-gap: 0 1rem;
  gap: 0 1rem;
  grid-template-areas: "title actions" "body actions";
  grid-template-columns: 1fr auto;
}

.ManageValues_value__1Rta3.ManageValues_is_editable__-Tfz6 {
  grid-template-areas: "editable title actions" "editable body actions";
  grid-template-columns: 1rem 1fr auto;
}

.ManageValues_value__1Rta3:not(:first-child) {
  /* padding-top: 2rem; */
  border-top: 1px solid var(--color-light-grey);;
}

.ManageValues_value__header__1mXuk {
  grid-area: title;
}

.ManageValues_value__header__1mXuk h3,
.ManageValues_value__body__16-9O p {
  margin: 0;
}

.ManageValues_value__body__16-9O {
  grid-area: body;
}

.ManageValues_value__actions__17qXB {
  grid-area: actions;
  align-self: start;
}

.ManageValues_value__edit__3oEvj {
  grid-area: editable;
}

.PasswordRecoveryScreen_container__1uNo2 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

[data-mobile='true'] .PasswordRecoveryScreen_loginContainer__3Z79x {
    background-color: #ffffff;
    border-radius: var(--space-8);
    display: flex;
    flex-direction: column;
    padding: calc(var(--space-24) + var(--space-24));
    width: 100%;
    height: 100%;
    padding-top: 20vh;
}

[data-mobile='false'] .PasswordRecoveryScreen_loginContainer__3Z79x {
    background-color: #ffffff;
    border-radius: var(--space-8);
    box-shadow: 0px 2px 10px #b8b8b863;
    display: flex;
    flex-direction: column;
    padding: calc(var(--space-24) + var(--space-24));
    width: 384px;
}

.PasswordRecoveryScreen_formContainer__Y4-nH{
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
}

.PasswordRecoveryScreen_formContainer__Y4-nH > * > input {
    text-align: center;
}

.PasswordRecoveryScreen_centered__3nSHt {
    display: flex;
    justify-content: center;
}

.PasswordReset_container__16CbC {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

[data-mobile='true'] .PasswordReset_loginContainer__nD_Y9 {
    background-color: #ffffff;
    border-radius: var(--space-8);
    display: flex;
    flex-direction: column;
    padding: calc(var(--space-24) + var(--space-24));
    width: 100%;
    height: 100%;
    padding-top: 20vh;
}

[data-mobile='false'] .PasswordReset_loginContainer__nD_Y9 {
    background-color: #ffffff;
    border-radius: var(--space-8);
    box-shadow: 0px 2px 10px #b8b8b863;
    display: flex;
    flex-direction: column;
    padding: calc(var(--space-24) + var(--space-24));
    width: 384px;
}

.PasswordReset_formContainer__2j5Qb{
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
}

.PasswordReset_formContainer__2j5Qb > * > input {
    text-align: center;
}

.PasswordReset_centered__3rqZE {
    display: flex;
    justify-content: center;
    text-align: center;
}

.PasswordReset_link__22Jib {
    color: var(--color-primary);
}

.ProfileScreen_profile_settings_card__-C3H- {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .ProfileScreen_profile_settings_card__-C3H- {
  margin-top: 32px;
  padding: 32px;
}

.ProfileScreen_label__3PTGK {
  display: flex;
  align-items: center;
}

.ReportScreen_container__3bgWW {
  --padding: 24px 16px;
}

[data-desktop='true'] .ReportScreen_container__3bgWW {
  --padding: 32px 64px;
}

.ReportScreen_report_period__2Kw9Z {
  padding-left: 16px;
  padding-top: 24px;
}

[data-desktop='true'] .ReportScreen_report_period__2Kw9Z {
  padding-left: 32px;
}

.ReportScreen_header__1o7xh {
  padding: var(--padding);
}

[data-desktop='true'] .ReportScreen_header__1o7xh {
  padding: var(--padding);
}

.ReportScreen_employee_info__1tW67 {
  display: flex;
  justify-content: space-between;
}

.ReportScreen_employee__nxtYU {
  display: flex;
  align-items: center;
}

.ReportScreen_performance_evaluation_container__1ntOx {
  padding: var(--padding);
}

[data-desktop='true'] .ReportScreen_performance_evaluation_container__1ntOx {
  padding: var(--padding);
}

.ReportScreen_goal__14Mi1 {
  padding: var(--padding);
}

[data-desktop='true'] .ReportScreen_goal__14Mi1 {
  padding: var(--padding);
}

[data-desktop='true'] .ReportScreen_indented__2JNzB {
  padding-left: 40px;
}

.ReportScreen_high_fives_container__1-KQo {
  padding: var(--padding);
}

[data-desktop='true'] .ReportScreen_high_fives_container__1-KQo {
  padding: var(--padding);
  padding-bottom: 36px;
}

.GoalReviewsContainer_review__31_i5 {
  padding-top: 24px;
}

[data-desktop='true'] .GoalReviewsContainer_review__31_i5 {
  padding-top: 32px;
  padding-left: 40px;
}

.ReportsScreen_reports_card__2PzCl {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .ReportsScreen_reports_card__2PzCl {
  margin-top: 32px;
  padding: 32px;
}

.ReportsScreen_label__KgmFa {
  display: flex;
  align-items: center;
}

.SettingsScreen_profile_settings_card__1Y6k9 {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .SettingsScreen_profile_settings_card__1Y6k9 {
  margin-top: 32px;
  padding: 32px;
}

.SettingsScreen_label__dt1tX {
  display: flex;
  align-items: center;
}

.UsersScreen_users__1-QQF {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .UsersScreen_users__1-QQF {
  margin-top: 32px;
  padding: 32px;
}

.UsersScreen_actions__1s-Fj {
  display: flex;
  justify-content: flex-end;
}

.UsersScreen_actions__1s-Fj div {
  margin-right: auto;
}

.UsersScreen_actions__1s-Fj input {
  width: 18em;
}

.UsersScreen_actions__1s-Fj button {
  margin: var(--space-4);
}

.Routes_app_loader__2-sZa {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Routes_app_loader__2-sZa svg {
    animation-duration: 0.2s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-name: Routes_pulse__3bjwO;
}

@keyframes Routes_pulse__3bjwO {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}

* {
  box-sizing: border-box;
}

html,
input,
select,
textarea,
button {
  font-family: 'Roboto', -apple-system, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  color: var(--color-dark-grey);
}

body {
  margin: 0;
  font-size: 13px;
  background-color: #f7f7f7;
}

button {
  -webkit-appearance: none;
          appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
}

button:focus {
  outline-color: var(--color-dark-grey);
}

a {
  color: inherit;
  text-decoration: inherit;
}
a:hover {
  color: var(--color-primary);
}

label {
  cursor: pointer;
}

/* Remove all animations and transitions for people that prefer not to see them */
/* https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

