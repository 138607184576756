.container {
  position: fixed;
  padding: 8px;
  top: 65px;
  z-index: 999;
  width: 100vw;
}

[data-desktop='true'] .container {
  padding: 16px;
  top: 72px;
  right: 0;
  width: 400px;
}
