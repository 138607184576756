.goalScreen {
    background-color: #ffffff;
    border-radius: var(--space-8);
    box-shadow: 0px 2px 10px #b8b8b863;
    display: flex;
    flex-direction: column;
}

.pad32 > div:first-child, .pad16 > div:first-child {
    justify-content: space-between;
    align-items: flex-start;
}

.pad32 {
    padding: var(--space-32) var(--space-32) 0 var(--space-32);
}

.pad16 {
    padding: var(--space-16) var(--space-16) var(--space-4) var(--space-16);
}
