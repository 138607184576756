.app_loader {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.app_loader svg {
    animation-duration: 0.2s;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-name: pulse;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.2);
    }
}
