.performanceReviewModal > div {
  --close-button-color: var(--color-white);
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 60em;
}

.header {
  padding: var(--space-32) var(--space-32) 3em;
  text-align: center;
  background: #94d1b3;
  color: white;
}

.header small {
  display: block;
  margin-bottom: 0.3em;
}

.body {
  padding: 0 var(--space-32);
  text-align: left;
}

.footer {
  display: flex;
  justify-content: center;
  padding: var(--space-8) var(--space-32);
  background: var(--color-light-grey);
}

.period {
  font-style: italic;
}

.comments {
  max-width: 40em;
  margin: -2.5em auto 3em;
}

.comment {
  padding: 0 var(--space-16);
  margin: 0;
  background: white;
}

.comment:first-child {
  padding-top: var(--space-16);
  padding-bottom: var(--space-16);
  margin-bottom: 1em;
  box-shadow: 0 0 1em rgba(0 0 0 / 0.2);
}

.scores {
  max-width: 40em;
  margin: 0 auto;
}

.test {
  background: red;
}

@media screen and (min-width: 1200px) {
  .container {
    display: flex;
    flex-direction: column;
    width: 60em;
  }
}
