.review__scores,
.review__notes {
  padding: 24px;
}

.review__notes {
  padding-top: 0;
}

.review__scores .score {
  border-bottom: 1px solid #000;
}
