.tab {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--color-grey);
  padding: 12px 8px;
  /* white-space: nowrap; */
}

.tab_active {
  color: var(--color-dark-grey);
  font-weight: bold;
}

.tab_active .count {
  color: var(--color-primary);
}

.tab_desktop.tab_active .count {
  color: #fff;
}
