.back_button {
  margin-right: var(--space-16);
  transform: rotate(180deg);
}

.team_header {
  display: flex;
}

.team_header h1 {
  margin-right: var(--space-32);
}

.search_wrapper {
  position: relative;
  width: 100%;
}
[data-desktop='true'] .search_wrapper {
  margin-left: auto;
  margin-right: 0;
  max-width: 300px;
  align-self: end;
}
.search {
  border-radius: var(--space-16);
  height: var(--space-32);
  border: 1px solid var(--color-light-grey);
  padding: 0 var(--space-16) 0 38px;
  width: 100%;
  font-size: 16px;
}
.search::placeholder {
  color: #aaaaaa;
}

.search_icon {
  position: absolute;
  left: 12px;
  top: 5px;
  pointer-events: none;
}

.employee_list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: var(--space-16);
}

[data-desktop='true'] .employee_list {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.employee_list li {
  display: flex;
  flex-direction: column;
}

.employee_link {
  text-decoration: none;
  color: inherit;

  display: flex;
  flex-direction: column;
  flex: 1;
}

