.button {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  box-shadow: none;
  border-radius: 50px;
  color: var(--color-dark-grey);
  background-color: #fff;
}

[data-desktop='true'] .button {
  color: var(--color-grey);
  background-color: transparent;
}

.button:hover {
  color: var(--color-dark-grey);
  background-color: #fff;
}

.button svg {
  transition: all 0.2s;
}

.button:hover svg {
  transform: scale(1.15);
}
