.container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
}

[data-mobile='true'] .loginContainer {
    background-color: #ffffff;
    border-radius: var(--space-8);
    display: flex;
    flex-direction: column;
    padding: calc(var(--space-24) + var(--space-24));
    width: 100%;
    height: 100%;
    padding-top: 20vh;
}

[data-mobile='false'] .loginContainer {
    background-color: #ffffff;
    border-radius: var(--space-8);
    box-shadow: 0px 2px 10px #b8b8b863;
    display: flex;
    flex-direction: column;
    padding: calc(var(--space-24) + var(--space-24));
    width: 384px;
}

.formContainer{
    flex: 1 0 auto;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
}

.formContainer > * > input {
    text-align: center;
}

.centered {
    display: flex;
    justify-content: center;
}
