.evaluation_periods {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-areas:
    "intro button"
    "table table";
  align-items: center;
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .evaluation_periods {
  margin-top: 32px;
  padding: 32px;
}

.evaluation_periods p {
  grid-area: intro;
}

.evaluation_periods button {
  grid-area: button;
}

.evaluation_periods table {
  grid-area: table;
}
