.review__status {
  display: flex;
  align-items: center;
  height: 22px;
  padding: 0 16px;
  border-radius: 11px;
  position: relative;
  background-color: var(--color);
  line-height: 22px;
  user-select: none;
  white-space: nowrap;
}

.review__progress {
  flex: 1 0 auto;
}

.review__date {
  display: flex;
  gap: 0.3em;
  font-size: 11px;
  color: var(--color-grey);
}

.extras {
  margin-top: var(--spacing);
}

@media screen and (min-width: 961px) {
  .extras {
    display: flex;
    gap: var(--spacing);
    align-items: center;
  }
}

@media screen and (max-width: 960px) {
  .extras {
    display: grid;
    gap: var(--spacing);
    grid-template-columns: auto 1fr;
  }

  .review__date {
    justify-self: end;
  }

  .review__progress {
    grid-column: 1 / -1;
    grid-row: 2;
  }
}

.review__comments {
  padding: 0 var(--spacing) var(--spacing);
}

.more_info {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin: 2em 2em 0 0;
  background: var(--color-light-grey);
  color: var(--color-dark-grey);
  font-size: 1em;
  float: right;
}
