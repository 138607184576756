.select {
    font-size: 14px;
    height: 32px;
    padding: 8px;
    border: 1px solid var(--color-light-grey);
    border-radius: 4px;
}

.select:focus {
    outline-color: var(--color-dark-grey);
    outline-offset: 0;
    outline-width: 1px;
}
