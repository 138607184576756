.card {
  --spacing: 16px;
}

.summary {
  padding: var(--spacing);
  position: relative;
  /* --background is set dynamically from component */
  background-color: var(--background);
  border-radius: var(--space-8);
}
.summary_expanded {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.toggle {
  cursor: pointer;
  transition: transform 0.3s;
  /* this is ok here, since the title is accessible and has the same feature and the element has tabIndex -1 */
  outline: none;
}
.toggle_open {
  transform: rotate(180deg);
}
.toggle:hover {
  transform: translateY(2px);
}
.toggle_open:hover {
  transform: rotate(180deg) translateY(2px);
}

.content {
  flex: 1;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
}

.footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.title {
  padding: 0;
  line-height: 1.2;
  text-align: start;
  padding-right: 2em;
}
.title_button {
  cursor: pointer;
}
.title_button:hover {
  color: var(--color-primary);
}
