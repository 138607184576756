.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: var(--header-height);
  padding: 0 16px;
  border-bottom: 1px solid var(--color-light-grey);
  background-color: #ffffff;
}

[data-desktop='true'] .header {
  padding: 0 32px;
}

.logo {
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  color: var(--color-dark-grey);
}

[data-desktop='true'] .logo {
  font-size: 20px;
}

.logo svg {
  margin-left: 0.5em;
  transition: all 0.2s;
}

.logo:hover svg {
  transform: scale(1.15);
}

.period {
  display: flex;
  align-items: center;
  margin-top: 0.3em;
  position: relative;
  color: var(--color-dark-grey);
}

@media (min-width: 560px) {
  .navigation {
    display: flex;
    align-items: center;
  }

  .period {
    padding-left: 3em;
    margin-top: 0;
  }
}

.period button:hover svg path {
  fill: var(--color-primary);
}

.evaluation_period {
  padding-left: 1em;
  margin-left: 1em;
  border-left: 1px solid var(--color-dark-grey);
  color: var(--color-primary);
}

.menu {
  display: flex;
  align-items: center;
}

.team {
  display: flex;
  align-items: center;
}

.team_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: var(--color-light-grey);
  border-radius: 50%;
}

.profile {
  position: relative;
}

.profile_button {
  display: flex;
  align-items: center;
  line-height: 1.3;
  text-align: start;
}

.profile_details {
  display: block;
  padding: 0 1.25em;
  text-transform: capitalize;
}

.profile_options {
  display: flex;
  flex-direction: column;
  min-width: 180px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 10;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.profile_options button {
  padding: 15px 25px;
  border-top: 1px solid var(--color-light-grey);
  line-height: 1.3;
  text-align: left;
}

.profile_options button:first-child {
  border: none;
}

.profile_options button:hover {
  color: var(--color-primary);
}

.period_options,
.cycle_options {
  display: flex;
  flex-direction: column;
  min-width: 180px;
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.period_options button,
.cycle_options button {
  padding: 15px 25px;
  line-height: 1.3;
  text-align: left;
}

.period_options button:not(:first-child),
.cycle_options button:not(:first-child) {
  border-top: 1px solid var(--color-light-grey);
}

.period_options button:hover,
.period_options button:focus,
.cycle_options button:hover,
.cycle_options button:focus {
  color: var(--color-primary);
}
