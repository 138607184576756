.goal_details {
  --spacing: 24px;
  background-color: white;
  border-bottom-left-radius: var(--space-8);
  border-bottom-right-radius: var(--space-8);
}

.padded {
  padding: var(--spacing);
}

[data-desktop='true'] .review_form_container {
  padding: 24px;
}
