.reports_card {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .reports_card {
  margin-top: 32px;
  padding: 32px;
}

.label {
  display: flex;
  align-items: center;
}
