.tasks {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 100%;
}

[data-desktop='true'] .tasks_container {
  padding-left: 24px;
  padding-right: 24px;
}

.tasks_list {
  overflow-y: auto;
  flex: 1;
  max-height: 100%;
}

.task {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
