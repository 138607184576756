@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.radio {
  position: relative;
  min-width: 24px;
  height: 24px;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--color-light-grey);
  border-radius: 50%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.radio:checked {
  padding: 4px;
  background-color: white;
}

.radio:checked::before {
  content: '';
  background-color: var(--color-secondary);
  display: block;
  width: 100%;
  border-radius: 50%;
}

.radio {
  --focus: 1px var(--color-dark-grey);
  outline: none;
  transition: box-shadow 0.2s;
}

.radio:hover,
.radio:focus {
  box-shadow: 0 0 0 var(--focus);
}
