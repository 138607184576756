.goal_form {
  --spacing: var(--space-16);
  padding: var(--spacing);
}

[data-desktop='true'] .goal_form {
  --spacing: var(--space-32);
}

.smart_box {
  background: #f9f9f9;
  border-radius: var(--space-8);
}

.smart_box_padded {
  padding: var(--space-16);
}

.smart_box_details {
  padding: 24px calc(40px + 16px);
}

.grid {
  display: grid;
  grid-template-columns: 40px max-content max-content;
  align-items: center;
}

[data-desktop='true'] .grid {
  column-gap: 16px;
  grid-column-start: auto;
}

.grid_label {
  grid-column: span 2;
}

.grid_content {
  grid-column-start: 2;
}

[data-desktop='true'] .grid_content {
  grid-column-start: auto;
}

.grid_divider {
  height: 2em;
  grid-column: span 3;
}

.word_count {
  margin-top: -1.5em;
  margin-right: 0.5em;
  position: relative;
  z-index: 1;
  float: right;
  font-size: 10px;
}
.word_count ins {
  text-decoration: none;
}

.word_count_over {
  color: red;
}
