.notification {
  position: relative;
  padding: 20px 16px;
}

.close_btn {
  position: absolute;
  top: 12px;
  right: 16px;
}
