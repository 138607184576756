@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

html,
input,
select,
textarea,
button {
  font-family: 'Roboto', -apple-system, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
  color: var(--color-dark-grey);
}

body {
  margin: 0;
  font-size: 13px;
  background-color: #f7f7f7;
}

button {
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  font: inherit;
}

button:focus {
  outline-color: var(--color-dark-grey);
}

a {
  color: inherit;
  text-decoration: inherit;
}
a:hover {
  color: var(--color-primary);
}

label {
  cursor: pointer;
}

/* Remove all animations and transitions for people that prefer not to see them */
/* https://dev.to/hankchizljaw/a-modern-css-reset-6p3 */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
