.container {
  --height: 32px;

  width: 100%;
  height: var(--height);
  position: relative;
}

.trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 13px;
  padding: 0 calc(var(--height) / 2);
  height: var(--height);
  cursor: pointer;
  border: 1px solid #d3d3d3;
  border-radius: calc(var(--height) / 2);
  background-color: #ffffff;
}
.selected_label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 1em;
  text-align: start;
}

.container_open .trigger,
.trigger:hover {
  background-color: #f8f8f8;
}
.container .trigger:focus {
  outline: 0;
  box-shadow: 0 0 0 1px var(--color-dark-grey) inset;
  border-color: var(--color-dark-grey);
}

.container_open .trigger {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-color: #f8f8f8;
}

.container_placeholder .trigger {
  color: var(--color-grey);
}

.options {
  position: absolute;
  z-index: 1;
  top: var(--height);
  left: 0;
  right: 0;
  background-color: #f8f8f8;
  border: 1px solid #d3d3d3;
  border-top: 0;
  border-bottom-left-radius: calc(var(--height) / 2);
  border-bottom-right-radius: calc(var(--height) / 2);
  padding: calc(var(--height) / 2) 0;
  max-height: 200px;
  overflow-y: auto;
}

.option {
  padding: 0;
  width: 100%;
  text-align: start;
  padding: calc(var(--height) / 4) calc(var(--height) / 2);
  line-height: 1.2;
}

.option:hover {
  background-color: var(--color-light-grey);
}
