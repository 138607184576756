.select {
    font-size: 14px;
    padding: 8px;
    border-radius: 4px;
    background: #f7f7f7;
}

.checkbox {
    display: inline-block;
    width: 1em;
    height: 1em;
    border: 1px solid #000;
    border-radius: 0.2em;
}

.checkbox:checked {
    background: var(--color-secondary) url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' viewBox='0 0 1331 1024' fill='rgb(255,255,255)'%3E%3Cpath d='M1199.452 0.051c-34.596 1.253-65.521 16-87.857 39.104l-0.035 0.036c-244.86 245.423-431.816 450.274-659.087 682.275l-242.074-204.519c-22.074-18.653-50.853-29.991-82.28-29.991-70.618 0-127.866 57.247-127.866 127.866 0 39.191 17.632 74.264 45.395 97.719l0.19 0.156 332.343 281.213c22.082 18.694 50.887 30.060 82.348 30.060 35.213 0 67.101-14.239 90.219-37.273l-0.004 0.004c275.333-275.921 471.365-496.316 741.381-766.945 24.073-23.276 39.019-55.862 39.019-91.939 0-70.595-57.229-127.824-127.824-127.824-1.36 0-2.715 0.021-4.065 0.063l0.197-0.005z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 0.625em 0.625em;
    background-position: center;
    border-color: transparent;
}

.option {
    display: flex;
    align-items: center;
    gap: 0.5em;
    margin: 0.25em 0;
}

.heading {
    display: flex;
    padding: 0.5em 1em;
}

.heading label {
    display: flex;
    gap: 0.25em;
}

.selectList {
    height: 15em;
    padding: 0.5em 1em;
    border: 1px solid var(--color-light-grey);
    border-radius: 4px;
    overflow: auto;
    background: var(--color-white);
}
