.overlay {
  --close-button-color: var(--color-grey);
  --close-button-hover: var(--color-primary);

  position: fixed;
  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* we don't really need this z-index but I used it to 'override' the styleguide z-index */
  z-index: 9999;
  overflow: auto;
  cursor: pointer;
}

[data-desktop='true'] .overlay {
  padding: 36px;
}

.modal {
  position: relative;
  display: flex;
  justify-content: center;
  cursor: initial;
  background-color: #fff;
  align-items: flex-start;
  height: auto;
  width: auto;
  border-radius: 8px;
  box-shadow: 0px 2px 14px #b8b8b863;
}

.modal_fullscreen {
  align-items: center;
  max-width: 100vw;
  /* height: 100%;
  width: 100%; */
  border-radius: 0;
  box-shadow: none;
}

.children {
  max-height: 100%;
  max-width: 100%;
}

.closeBtn {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 0;
  color: var(--close-button-color);
}
.closeBtn:hover {
  color: var(--color-primary);
}
