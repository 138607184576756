.highlight {
  animation-duration: 3s;
  animation-name: highlight;
}

@keyframes highlight {
  0% {
    box-shadow: 0px 0px 6px #ff000000;
  }
  10% {
    box-shadow: 0px 0px 16px var(--color-primary);
  }
  20% {
    box-shadow: 0px 0px 6px #ff000000;
  }
  30% {
    box-shadow: 0px 0px 16px var(--color-primary);
  }
  100% {
    box-shadow: 0px 0px 6px #ff000000;
  }
}
