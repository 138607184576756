.employeeHeader {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 2em;
}

.fixed_give_high5_button {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 99;
}

.left {
  display: flex;
  align-items: center;
}

.employee {
  display: flex;
  align-items: center;
  color: #000000;
}

.employee:hover {
  color: var(--color-primary);
}

.right {
  display: flex;
  align-items: center;
}

.high5sDisplay {
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: bold;
  color: var(--color-dark-grey);
  height: 40px;
  padding: 0 12px;
  background-color: #fff;
  border-radius: 50px;
}

.dotsMenu {
  position: relative;
  z-index: 1;
}

.dotsBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #fff;
}

[data-desktop='true'] .dotsBtn {
  background-color: transparent;
}

.dotsBtn svg path {
  fill: var(--color-primary);
}

[data-desktop='true'] .dotsBtn svg path {
  fill: var(--color-dark-grey);
}

.dotsBtn:hover {
  border-radius: 50%;
  background-color: #fff;
}

.dotsBtn:hover svg path {
  fill: var(--color-primary);
}

.dotsBtn:focus {
  border-radius: 50%;
  background-color: #fff;
}

.dotsBtn:focus svg path {
  fill: var(--color-primary);
}

.menuOptions {
  position: absolute;
  top: 48px;
  right: 0;
  min-width: 200px;
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.plus {
  color: var(--color-primary);
}

.menuOption {
  text-align: start;
  padding: 0;
  cursor: pointer;
}
