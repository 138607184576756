.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 420px;
  max-width: 100%;
  padding: 40px 32px;
}

.manager_profile {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border: 1px solid var(--color-light-grey);
  border-radius: 24px;
}

.profile_image_wrapper {
  position: absolute;
  top: 2px;
  left: 2px;
}
