.input-range {
  height: 16px;
  display: flex;
  align-items: center;
}
.input-range__track {
  background-color: #f0f0f0;
  height: 16px;
  border-radius: 8px;
  flex: 1;
  position: initial;
}
.input-range--disabled .input-range__track--active,
.input-range__track--active {
  background-color: #c3ead8;
}
.input-range__track--background {
  margin-top: auto;
  top: initial;
  right: initial;
}

.input-range__slider {
  border: none;
  width: 32px;
  height: 32px;
  margin-left: -16px;
  margin-top: -24px;
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  cursor: ew-resize;
}

/* make this same size & position as .input-range__slider */
.input-range__label--value {
  z-index: 1;
  top: 0;
  width: 32px;
  height: 32px;
  margin-left: -16px;
  margin-top: -24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #28c37b;
  pointer-events: none;
  font-size: 12px;
  font-weight: bold;
}
.input-range__label-container {
  left: auto;
  display: block;
  text-align: center;
  pointer-events: none;
  user-select: none;
}

.input-range__label--max,
.input-range__label--min {
  display: none;
}

.input-range--disabled .input-range__slider,
.input-range--disabled .input-range__track {
  cursor: initial;
}
.input-range--disabled .input-range__slider-container {
  display: none;
}
/* .input-range--disabled .input-range__slider {
  box-shadow: none;
  background: none;
} */
