.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 640px;
  max-width: 100%;
  padding: 40px 32px;
}

.actions {
  display: flex;
  gap: 1rem;
}
