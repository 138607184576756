.ratings {
  display: flex;
  gap: 1rem;
}

.rating {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5em 0.75em;
  background: #e3e3e3;
  border: none;
  border-radius: 1em;
  font-size: 0.875rem;
}

.rating:disabled {
  cursor: default;
}

.rating.active {
  background-color: #94d1b3;
  color: white;
}

@media screen and (min-width: 540px) {
  .rating {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1024px) {
  .rating {
    min-width: 10em;
    padding: 0.75em 1em;
  }
}
