[data-desktop='true'] .check_in_container {
  padding: 24px;
}

.review_container {
  padding: 24px 16px;
}

[data-desktop='true'] .review_container {
  padding: 24px 40px;
}

.pip_container {
  padding: 24px 16px;
}

[data-desktop='true'] .pip_container {
  padding: 24px;
}

.pip_card {
  padding: 24px 16px;
}

.pip_content {
  display: flex;
  align-items: baseline;
}

[data-desktop='true'] .pip_text_wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.pip_documents {
  flex: 1 1 100%;
  padding: 0;
  margin: 1rem 0;
  list-style: none;
}

.pip_document {
  display: flex;
  gap: 1rem;
}

.pip_document a {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.file_button {
  font-size: 0.875em;
  text-transform: uppercase;
}

.file_button:hover,
.file_button:focus {
  color: var(--color-primary);
}
