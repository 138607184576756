.container {
  --padding: 24px 16px;
}

[data-desktop='true'] .container {
  --padding: 32px 64px;
}

.report_period {
  padding-left: 16px;
  padding-top: 24px;
}

[data-desktop='true'] .report_period {
  padding-left: 32px;
}

.header {
  padding: var(--padding);
}

[data-desktop='true'] .header {
  padding: var(--padding);
}

.employee_info {
  display: flex;
  justify-content: space-between;
}

.employee {
  display: flex;
  align-items: center;
}

.performance_evaluation_container {
  padding: var(--padding);
}

[data-desktop='true'] .performance_evaluation_container {
  padding: var(--padding);
}

.goal {
  padding: var(--padding);
}

[data-desktop='true'] .goal {
  padding: var(--padding);
}

[data-desktop='true'] .indented {
  padding-left: 40px;
}

.high_fives_container {
  padding: var(--padding);
}

[data-desktop='true'] .high_fives_container {
  padding: var(--padding);
  padding-bottom: 36px;
}
