.container {
  display: flex;
  flex: 1 1 0;
}

.comment {
  flex: 1 1 0;
}

.body {
  padding: 12px 16px;
  background-color: #f0f0f0;
  border-radius: 4px;
  white-space: pre-line;
}

.header {
  display: flex;
  justify-content: space-between;
}

[data-desktop='true'] .delete {
  visibility: hidden;
}
[data-desktop='true'] .container:hover .delete {
  visibility: visible;
}
.delete:hover svg path {
  fill: var(--color-primary);
}
