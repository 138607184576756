.performanceDefinitionsModal > div {
  --close-button-color: var(--color-white);
}

.container {
  display: flex;
  flex-direction: column;
  max-width: 60em;
}

.header {
  padding: var(--space-32);
  text-align: center;
  background: #94d1b3;
  color: white;
}

.body {
  padding: 0 var(--space-32);
  text-align: left;
}

.definitions {
  display: grid;
}

.definition {
  padding: var(--space-16);
}

.definition:not(:last-child) {
  border-bottom: 1px solid var(--color-light-grey);
}

.definition__term {
  font-weight: 700;
}

.definition__data ul {
  padding: 0 0 0 1em;
  margin: 0;
}

@media screen and (min-width: 1200px) {
  .container {
    display: flex;
    flex-direction: column;
    width: 60em;
  }
}

@media screen and (min-width: 481px) {
  .definition {
    display: grid;
    grid-template-columns: 5em 1fr;
    gap: 1em;
  }
}
