.progress {
  display: flex;
  gap: var(--space-24);
  align-items: center;
}

.progress.labelBefore {
  flex-direction: row-reverse;
}

.progress__label {
  font-weight: bold;
  color: var(--color-grey);
}
