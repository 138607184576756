.content {
  flex: 1;
}

.description {
  margin-right: 2em;
}

.footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.actions_wrapper {
  background-color: var(--background);
  padding-left: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  height: calc(32px + 2px + 2px);
  display: flex;

  position: absolute;
  bottom: 10px;
  right: 8px;
  padding-right: 8px;

  overflow: hidden;
  will-change: contents;
  width: 50px;
  transition: width 0.3s, border 0.3s;
}
[data-desktop='false'] [data-actions='true'] .actions_wrapper {
  border-left: 1px var(--color-light-grey) solid;
  width: 160px;
}

.actions_toggle {
  padding: 4px;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
}

.actions {
  display: flex;
  margin-left: 32px;
  transition: transform 0.3s;
}
[data-actions='false'] .actions {
  transform: translateX(80px);
}
[data-actions='true'] .actions {
  transform: translateX(0);
}
[data-desktop='true'] .actions {
  margin: 0;
  transform: translateY(5px) translateX(5px);
  visibility: hidden;
}
[data-desktop='true'] .goal:hover .actions {
  visibility: visible;
}

.actions button {
  padding: 8px;
  line-height: 1;
}
[data-desktop='false'] .actions button {
  margin-left: 8px;
}
.actions button:hover {
  background-color: #eeeeee;
  border-radius: 50%;
}

.goal_details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* force stats to wrap on mobile, so the absolute positioned toggle doesn't cover them */
  padding-right: 42px;
  flex: 1;
}

.goal_progress,
.goal_stats {
  display: flex;
  align-items: center;
  /* leave a vertical space when stats wrap below status */
  margin-top: 1em;
}
[data-desktop='true'] .goal_progress {
  flex: 1;
  min-width: 200px;
  max-width: 300px;
}
.goal_progress_mobile {
  padding: calc(var(--spacing) * 2) var(--spacing);
  margin: 0 auto;
  max-width: 400px;
}

.status {
  margin-top: 1em;
  position: relative;
  user-select: none;
  /* mandatory to align status with stats */
  margin-top: 1em;
}

.status_summary {
  height: 22px;
  line-height: 22px;
  border-radius: 11px;
  /* --color is set dynamically from component */
  background-color: var(--color);
  padding: 0 16px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.status_summary:disabled {
  cursor: default;
}

.status_list {
  position: absolute;
  z-index: 2;
  margin-top: 0.5em;
  padding: 8px 16px;
}

.status_list button:hover {
  color: var(--color-primary);
}

.status_list button {
  text-align: left;
  white-space: nowrap;
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.status_list button::before {
  content: '';
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--color);
  border-radius: 50%;
  margin-right: 8px;
}
