.employee {
  padding: var(--space-16);
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

[data-desktop='true'] .employee {
  flex-direction: row;
  text-align: initial;
}

.employee:hover {
  box-shadow: 0px 2px 10px #999999;
}

.stats {
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-desktop='true'] .stats {
  justify-content: flex-start;
}
