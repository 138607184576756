.users {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .users {
  margin-top: 32px;
  padding: 32px;
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.actions div {
  margin-right: auto;
}

.actions input {
  width: 18em;
}

.actions button {
  margin: var(--space-4);
}
