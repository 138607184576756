.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 25em;
  position: relative;
}

.input {
  width: 100%;
  position: relative;
  z-index: 1;
}

.input:focus {
  outline-color: var(--color-dark-grey);
  outline-offset: 0;
  outline-width: 1px;
}

.input::placeholder {
  color: #999;
}

.error {
  flex: 0 0 100%;
  padding: 0.4em 0.5em 0.2em 0.5em;
  border-radius: 0 0 4px 4px;
  margin-top: -0.3em;
  background-color: red;
  text-align: center;
  color: white;
  font-size: 10px;
  font-weight: bold;
}

.centeredText {
  text-align: center;
}
