.container {
  width: 300px;
  padding: 32px;
}

.container[data-desktop='true'] {
  width: 420px;
}

.form {
  display: grid;
  gap: 16px;
  width: 100%;
}

.form select {
  width: 100%;
}
