.card {
  --spacing: var(--space-16);

  padding: var(--spacing);
}

.card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.extras {
  display: flex;
  gap: var(--spacing);
  align-items: center;
  margin-top: var(--spacing);
}

.review__status {
  display: flex;
  align-items: center;
  height: 22px;
  padding: 0 16px;
  border-radius: 11px;
  position: relative;
  background-color: var(--color);
  line-height: 22px;
  user-select: none;
  white-space: nowrap;
}

.review__progress {
  flex: 1 0 auto;
}

.review__date {
  display: flex;
  gap: 0.3em;
  font-size: 11px;
  color: var(--color-primary);
}

.extras {
  display: flex;
  gap: var(--spacing);
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing);
}

.review__comments {
  padding: 0 var(--spacing) var(--spacing);
}

.more_info {
  display: grid;
  justify-items: center;
  align-items: center;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  background: var(--color-light-grey);
  color: var(--color-dark-grey);
  font-size: 1em;
}

.more_info:hover,
.more_info:focus {
  background: var(--color-dark-grey);
  color: var(--color-white);
}
