.reports_card {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .reports_card {
  margin-top: 32px;
  padding: 32px;
}

.label {
  display: flex;
  align-items: center;
}

.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table th {
  text-align: left;
}

.table td:not(:first-child) {
  text-align: right;
}

.table tfoot td:first-child {
  font-weight: bold;
}

.onPIP {
  color: var(--color-primary);
}


