.users {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .users {
  margin-top: 32px;
  padding: 32px;
}

.form {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 0.5rem));
  gap: 1rem;
}

.managerInput select {
  width: 100%;
}

.employees {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 0.5rem));
  gap: 1rem;
  grid-column: span 2;
}

.managing {
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  background: #f7f7f7;
}

.managingHeader {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 0.5em 1em;
}

.managingHeader button {
  display: flex;
  align-items: center;
  gap: 0.25em;
  font-size: 0.875em;
  color: var(--color-primary);
}

.managingBody {
  height: 15em;
  padding: 0.5em 1em;
  border: 1px solid var(--color-light-grey);
  border-radius: 4px;
  overflow: auto;
  background: var(--color-white);
}

.managingEmployee {
  display: flex;
  gap: 0.5em;
  align-items: center;
  margin: 0.25em 0;
}

.actions {
  display: flex;
  gap: 1em;
  margin: 1em 0;
}
