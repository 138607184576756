.comments__beforeContent {
  color: red;
}

.new_comment {
  display: flex;
  margin-top: 16px;
}

.submit {
  padding: 0 var(--space-8);
}
.submit svg path {
  fill: var(--color-dark-grey);
}
.submit:disabled svg path {
  fill: var(--color-light-grey);
}
.submit:not(:disabled):hover svg path,
.submit:not(:disabled):active svg path {
  fill: var(--color-primary);
}
