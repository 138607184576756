.textarea {
  font-size: 16px;
  width: 100%;
  padding: 10px 14px;
  margin: 0;
  resize: none;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid var(--color-light-grey);
}

.textarea:focus {
  outline-color: var(--color-dark-grey);
  outline-offset: 0;
  outline-width: 1px;
}

.textarea::placeholder {
  color: #999;
}
