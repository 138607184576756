@supports (-webkit-appearance: none) or (-moz-appearance: none) {
  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
}

.checkbox[type='checkbox'] {
  position: relative;
  min-width: 24px;
  height: 24px;
  margin: 0;
  border: 1px solid var(--color-light-grey);
  border-radius: 20%;
}

.checkbox[type='checkbox']:checked {
  background-color: var(--color-secondary);
  border: 0;
}

.checkbox[type='checkbox'] {
  --focus: 1px var(--color-dark-grey);
  outline: none;
  transition: box-shadow 0.2s;
}

.checkbox[type='checkbox']:focus {
  box-shadow: 0 0 0 var(--focus);
}

.checkboxContainer {
  position: relative;
  cursor: pointer;
}
.iconContainer {
  position: absolute;
  left: 5px;
  top: 1px;
  z-index: 1;
}
