.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  height: 32px;
  width: fit-content;
  padding: 0 24px;
  cursor: pointer;
  border: none;
  border-radius: 16px;
}

.noWrap {
  white-space: nowrap;
}

.btn:focus {
  outline-color: var(--color-dark-grey);
  outline-offset: 0;
  outline-width: 1px;
}

.btn:disabled {
  cursor: inherit;
}

.small {
  height: 24px;
  padding: 0 18px;
}

.primary {
  background-color: var(--color-primary);
}

.primary:hover {
  background-color: #cc1019;
}

.primary:disabled {
  background-color: var(--color-light-grey);
}

.secondary {
  color: var(--color-primary);
  border: 1px solid #da4241;
  background-color: #fff;
}

.secondary:hover {
  color: #fff;
  background-color: var(--color-primary);
}

.secondary:disabled {
  color: var(--color-grey);
  border-color: var(--color-light-grey);
  background-color: #fff;
}

.tertiary {
  color: var(--color-grey);
  padding: 0;
  border: none;
  background-color: transparent;
}

.tertiary:hover {
  color: var(--color-primary);
}

.tertiary:disabled {
  color: var(--color-light-grey);
}
