.container {
  display: grid;
}

.meter {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 8px;
  border-radius: 3px;
}

.meter::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.meter::-moz-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.label {
  font-size: 10px;
  text-align: center;
}

.meter::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.meter::-moz-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.-Weak::-webkit-progress-value {
  background-color: #F25F5C;
}

.-Weak::-moz-progress-bar {
  background-color: #F25F5C;
}

.-Fair::-webkit-progress-value {
  background-color: #FFE066;
}

.-Fair::-moz-progress-bar {
  background-color: #FFE066;
}

.-Good::-webkit-progress-value {
  background-color: #247BA0;
}

.-Good::-moz-progress-bar {
  background-color: #247BA0;
}

.-Strong::-webkit-progress-value {
  background-color: #70C1B3;
}

.-Strong::-moz-progress-bar {
  background-color: #70C1B3;
}
