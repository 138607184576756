.dataTable {
  width: 100%;
  border-collapse: collapse;
  margin: var(--space-24) 0;
  background-color: var(--color-white);
}

.dataTable th,
.dataTable td {
  border: 1px solid var(--color-light-grey);
  padding: var(--space-8) var(--space-4);
}

.dataTable tbody tr:focus-within td,
.dataTable tbody tr:hover td {
  background: var(--color-light-grey);
}

.dataTable tbody tr:focus-within td:not(:first-child),
.dataTable tbody tr:hover td:not(:first-child) {
  border-left-color: var(--color-white);
}

.dataTable tbody tr:focus-within td:not(:last-child),
.dataTable tbody tr:hover td:not(:last-child) {
  border-right-color: var(--color-white);
}
