.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.input {
  font-size: 16px;
  height: 32px;
  width: 100%;
  padding: 8px;
  border: 1px solid var(--color-light-grey);
  border-radius: 4px;
  position: relative;
  z-index: 1;
}

.input:focus {
  outline-color: var(--color-dark-grey);
  outline-offset: 0;
  outline-width: 1px;
}

.input::placeholder {
  color: #999;
}

.large {
  font-size: 18px;
  height: 64px;
  padding: 24px;
}

.unit {
  position: absolute;
  right: 8px;
  color: #999;
}

.input:focus + .unit {
  color: var(--color-dark-grey);
}

.error {
  flex: 0 0 100%;
  padding: 0.4em 0.5em 0.2em 0.5em;
  border-radius: 0 0 4px 4px;
  margin-top: -0.3em;
  background-color: red;
  text-align: center;
  color: white;
  font-size: 10px;
  font-weight: bold;
}

.centeredText {
  text-align: center;
}
