.addGoalBtn {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: bold;
    color: var(--color-grey);
    height: 40px;
    padding: 0 18px;
}

.addGoalBtn:hover {
    color: var(--color-dark-grey);
    background-color: #fff;
    border-radius: 50px;
}

.addGoalBtn svg {
    transition: all 0.2s;
}
.addGoalBtn:hover svg {
    transform: scale(1.15);
}

[data-desktop='false'] .addGoalBtn {
    color: var(--color-dark-grey);
    font-weight: normal;
    background-color: #fff;
    border-radius: 50px;
}

[data-desktop='false'] .addGoalBtn:hover svg {
    transform: none;
}
