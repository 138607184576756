.goal {
  --spacing: 24px;

  padding: var(--spacing);
}

.line {
  height: 0.8125rem;
  background: #ccc;
  background-image: linear-gradient(
    -45deg,
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent
  );
  background-size: 500% 100%;
  animation: move 2s reverse linear infinite;
}

.heading {
  height: 1.25rem;
}

.short {
  width: 108px;
}

.goal_status {
  width: 108px;
  height: 22px;
  line-height: 22px;
  border-radius: 11px;
  background-color: #c3ead8;
  padding: 0 16px;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.goal_footer {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.spacer {
  height: 1rem;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}
