.profile_image {
  --size: 40px;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  min-width: var(--size);
  width: var(--size);
  height: var(--size);
}

.profile_image span {
  color: white;
  font-weight: bold;
  display: block;
  font-size: 16px;
  letter-spacing: -2px;
  transform: translateX(-1px);
}

.profile_image_large {
  --size: 64px;
}
.profile_image_large span {
  font-size: 24px;
  letter-spacing: -3px;
}

.profile_image_small {
  --size: 32px;
}
.profile_image_small span {
  font-size: 12px;
  letter-spacing: -1px;
}
