.tabs {
  display: grid;
  gap: 1rem;
}

.tabs__header {
  display: flex;
}

.tabs.hasTabs .tabs__header {
  border-bottom: 1px solid var(--color-light-grey);
}
