.container {
  padding: 24px 16px;
  background-color: #f7f7f7;
  border-radius: 0;
}

[data-desktop='true'] .container {
  border-radius: 8px;
}

.header {
  display: flex;
  align-items: center;
}

.content {
  padding: 0;
}

[data-desktop='true'] .content {
  padding: 0 40px;
}

.add_review_btn_wrapper {
  display: flex;
  justify-content: center;
}

[data-desktop='true'] .add_review_btn_wrapper {
  justify-content: flex-start;
}
