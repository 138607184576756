.pip {
  width: fit-content;
  padding: 6px 8px 5px 8px;
  display: inline-block;
  background-color: #ff8188;
  border-radius: 40px;
}

.small {
  padding: 4px 8px 3px 8px;
}
