.content {
  display: grid;
  grid-template-areas:
    'main_header'
    'sidebar'
    'main';
  grid-template-rows: auto auto 1fr;

  /* this was the only way to make it fill the entire height */
  /* flex: 1; stretches the background as expected, but not the content */
  height: calc(100vh - var(--header-height));
}

[data-desktop='false'] .content {
  overflow: auto;
}
[data-desktop='true'] .content {
  grid-template-areas:
    'main_header'
    'main';

  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
}
[data-desktop='true'] .content.with_sidebar {
  grid-template-areas:
    'main_header sidebar'
    'main sidebar';

  grid-template-columns: 1fr 400px;
}

.main {
  grid-area: main;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3em;
}
[data-desktop='true'] .main {
  overflow: auto;
}
.main_header {
  padding-top: 3em;
  grid-area: main_header;
  width: 100%;
}

.main_wrapper {
  max-width: calc(832px + 2 * 32px);
  width: 100%;
  padding: 0 12px;
}
[data-desktop='true'] .main_wrapper {
  padding: 0 32px;
}

.sidebar {
  grid-area: sidebar;
  position: relative;
  display: flex;
  flex-direction: column;
}

[data-desktop='true'] .sidebar {
  background-color: #ffffff;
  border-left: 1px solid var(--color-light-grey);
}

.sidebar_tabs {
  padding: 16px 0;
  overflow-x: auto;
  max-width: calc(100vw - 2 * 12px);
}
[data-desktop='true'] .sidebar_tabs {
  padding: 16px 24px;
  max-width: calc(100vw - 2 * 24px);
}

.scrollable {
  overflow: auto;
  flex: 1;
}

[data-desktop='false'] .high5s_wrapper {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 99;
}
[data-desktop='true'] .high5s_wrapper {
  border-top: 1px var(--color-light-grey) solid;
  padding: 16px;
}
.high5s_box {
  padding: 24px;
  background-color: #f5f5f5;
  border-radius: 8px;
  text-align: left;
  overflow: hidden;
  position: relative;
}
.high5s_content {
  position: relative;
}
.high5s_icon {
  color: #f0edee;
  position: absolute;
  right: 10px;
  bottom: -30px;
  transition: transform 0.3s;
}
.high5s_give_button {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
}
