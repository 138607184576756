.toggle_all_goals {
  padding: var(--space-16);
  display: flex;
  width: 100%;
  justify-content: center;
  font-weight: bold;
  color: var(--color-grey);
}
.toggle_all_goals svg {
  transition: all 0.3s;
}
.toggle_all_goals:hover {
  color: var(--color-dark-grey);
}
.toggle_all_goals:hover svg {
  transform: scale(1.2);
}

.add_goal_wrapper {
  display: flex;
  justify-content: center;
}
