.manage_values_card {
  padding: 16px;
  margin-top: 24px;
}

[data-desktop='true'] .manage_values_card {
  margin-top: 32px;
  padding: 32px;
}

.card_header,
.card_header__actions {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.label {
  display: flex;
  align-items: center;
}

.values__list {
  display: grid;
  /* gap: 2rem; */
}

.value {
  display: grid;
  gap: 0 1rem;
  grid-template-areas: "title actions" "body actions";
  grid-template-columns: 1fr auto;
}

.value.is_editable {
  grid-template-areas: "editable title actions" "editable body actions";
  grid-template-columns: 1rem 1fr auto;
}

.value:not(:first-child) {
  /* padding-top: 2rem; */
  border-top: 1px solid var(--color-light-grey);;
}

.value__header {
  grid-area: title;
}

.value__header h3,
.value__body p {
  margin: 0;
}

.value__body {
  grid-area: body;
}

.value__actions {
  grid-area: actions;
  align-self: start;
}

.value__edit {
  grid-area: editable;
}
