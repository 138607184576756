.container {
  width: 100%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 16px;
}

[data-desktop='true'] .header {
  padding: 32px;
}

.right {
  width: 50%;
}

[data-desktop='true'] .right {
  width: 70%;
}

.high_fives_info {
  display: flex;
  align-items: center;
  pointer-events: none;
}

.high_fives_info.expandable {
  cursor: pointer;
  pointer-events: auto;
}

.high_fives_count {
  padding-left: 8px;
  padding-right: 16px;
  font-size: 21px;
  font-weight: bold;
}

.company_values {
  padding: 0 8px;
}

[data-desktop='true'] .company_values {
  padding: 0 32px;
}

.company_value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
  padding-left: 8px;
  padding-right: 16px;
  pointer-events: none;
}

.company_value.expandable {
  cursor: pointer;
  pointer-events: auto;
}

[data-desktop='true'] .company_value {
  padding: 24px 0;
}

.company_value .high_five_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.high_five {
  display: flex;
  align-items: center;
}

.icon_wrapper {
  display: flex;
  justify-content: center;
  min-width: 45px;
}

.icon_with_count {
  display: flex;
  align-items: center;
}

.authors {
  display: flex;
}

.other_authors {
  position: relative;
}

.other_authors:hover .other_authors_list {
  visibility: visible;
}

.other_authors_list {
  position: absolute;
  visibility: hidden;
  top: calc(100% + 8px);
  right: 0;
  min-width: 200px;
  padding: 16px;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.comments {
  display: flex;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 16px;
}

[data-desktop='true'] .comments {
  padding: 0;
}

.comments .right {
  width: 100%;
}

[data-desktop='true'] .comments .right {
  width: 70%;
}

.comment {
  padding: 12px 18px;
  border-radius: 10px;
  width: 100%;
  background-color: #f0f0f0;
}
