.h1 {
  font-size: 26px;
  color: #000000;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
}

.h2 {
  font-size: 21px;
  color: #000000;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
}

.h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
}

.inherit_color {
  color: inherit;
}
