.container {
  --height: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  width: 420px;
  max-width: 100%;
}

.employee {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--height);
  border: 1px solid #d3d3d3;
  border-radius: calc(var(--height) / 2);
}

.employee:disabled {
  cursor: initial;
}

.clear_employee_btn {
  position: absolute;
  right: 5px;
  top: 0;
  bottom: 0;
  padding: 10px;
  color: var(--color-grey);
}
.clear_employee_btn:hover {
  color: var(--color-primary);
}
.clear_employee_btn svg {
  display: block !important;
}

.profile_image {
  position: absolute;
  top: 3px;
  left: 3px;
}

.dropdownContainer {
  width: 100%;
  height: var(--height);
}

.dropdownContent {
  position: relative;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  border-radius: 20px;
  overflow: hidden;
  /* we need z-index to overlap all content under the list.  */
  z-index: 1;
}

.dropdownContent.search {
  /* because search input and dropdown have the same z-index
    in order to show the list of employees on top of the list of
    company values we have to increase the z-index for search input */
  z-index: 2;
}

.options {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}

.option {
  padding: 0;
  width: 100%;
  height: 32px;
}

.option:hover {
  background-color: var(--color-light-grey);
}

.option:last-child {
  margin-bottom: 16px;
}

.search_icon {
  position: absolute;
  top: 8px;
  left: 14px;
}

.search_input {
  width: 100%;
  height: calc(var(--height) - 2px);
  border: none;
  text-align: center;
  padding: 0 14px;
  font-size: 16px;
}

.search_input:focus {
  outline: none;
}

.search_input::placeholder {
  color: var(--color-grey);
}

.select_value_btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: calc(var(--height) - 2px);
  padding: 0 14px;
  font-size: 16px;
}

.select_value_btn:focus {
  outline: none;
}

.select_value_btn:hover {
  background-color: #f8f8f8;
}
